// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file feed/feed.proto (package feed, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { NumericFeed } from "./numeric_feed_pb";
import { TimeSeriesFeed } from "./time_series_feed_pb";

/**
 * @generated from message feed.Feed
 */
export class Feed extends Message<Feed> {
  /**
   * @generated from field: bool predicted = 1;
   */
  predicted = false;

  /**
   * @generated from oneof feed.Feed.feed
   */
  feed: {
    /**
     * @generated from field: feed.NumericFeed numeric = 2;
     */
    value: NumericFeed;
    case: "numeric";
  } | {
    /**
     * @generated from field: feed.TimeSeriesFeed time_series = 3;
     */
    value: TimeSeriesFeed;
    case: "timeSeries";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Feed>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "feed.Feed";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "predicted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "numeric", kind: "message", T: NumericFeed, oneof: "feed" },
    { no: 3, name: "time_series", kind: "message", T: TimeSeriesFeed, oneof: "feed" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Feed {
    return new Feed().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Feed {
    return new Feed().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Feed {
    return new Feed().fromJsonString(jsonString, options);
  }

  static equals(a: Feed | PlainMessage<Feed> | undefined, b: Feed | PlainMessage<Feed> | undefined): boolean {
    return proto3.util.equals(Feed, a, b);
  }
}

