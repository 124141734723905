import { useAtom } from 'jotai';
import React from 'react';
import { Segment } from '../../protos/recommender/segment_pb';
import { segmentByIdAtom } from '../state/segments';
import DetailSwaps from './BooleanExpression/DetailSwaps';

const DetailAllSwaps: React.FC = () => {
  const [segments] = useAtom(segmentByIdAtom);
  const allSegments: Record<string, Segment> = {};

  for (const segment of Object.keys(segments)) {
    if (!segments[segment].swapTo) {
      continue;
    }

    allSegments[segment] = segments[segment];
  }

  return <DetailSwaps ids={Object.keys(allSegments)} />;
};

export default DetailAllSwaps;
