import { useAtom } from 'jotai';
import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../components/Loading/LoadingDots';
import Icon from '../../components/icons/Icon';
import { addSegmentsByTextAtom } from '../state/suggest';

const Styled = styled.form`
  display: flex;
  margin: 0.5rem 0 0;

  input {
    border: 0;
    border-bottom: 1px solid var(--color-border);
    border-radius: 4px;
    font-size: 0.9rem;
    flex: 1 0 0;
    max-width: 60%;
    padding: 0.2rem;
    width: 100%;
  }

  .ai-button {
    cursor: pointer;
    color: var(--color-assistance);
    background: var(--color-white);
    border-radius: 20px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    padding: 0.4rem 0.48rem;
  }
`;

const SegmentTextSearch: React.FC<{
  existingSelectedIdsInRun: string[];
  onSearch: (result: string[]) => void;
  existingPredictedIds: string[];
}> = ({ existingSelectedIdsInRun, onSearch, existingPredictedIds }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [, addSegmentsByText] = useAtom(addSegmentsByTextAtom);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await addSegmentsByText(
        searchTerm,
        existingPredictedIds,
        existingSelectedIdsInRun,
        2
      );
      if (result) onSearch(result);
    } finally {
      setLoading(false);
    }
  };

  // const client = withClient(Suggest);
  // const handleSuggest = async () => {
  //   setLoading(true);
  //   try {
  //     const query = names.sort(() => Math.random() - 0.5).slice(0, 2);
  //     const resp = await client.summaryName({ text: query.join('\n') });
  //     setSearchTerm(resp.text);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Styled onSubmit={handleSubmit}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Search for additional audiences and press enter"
      />
      {loading && <Loading />}
      {!loading && (
        <div className="ai-button" onClick={handleSubmit}>
          <Icon name="ai" />
        </div>
      )}
    </Styled>
  );
};

export default SegmentTextSearch;
