import React, { useEffect, useState } from 'react';

const HighContrastToggle: React.FC = () => {
  const [highContrast, setHighContrast] = useState(false);

  useEffect(() => {
    if (highContrast) {
      document.body.classList.add('-high-contrast');
    } else {
      document.body.classList.remove('-high-contrast');
    }
  }, [highContrast]);

  return (
    <button onClick={() => setHighContrast(!highContrast)}>
      {highContrast ? 'Default Colors' : 'High Contrast Colors'}
    </button>
  );
};

export default HighContrastToggle;
