import React, { useState } from 'react';
import styled from 'styled-components';
import { Graph } from '../../protos/graph/graph_pb';
import CampaignGraph from './CampaignGraph';

interface CampaignGraphProps {
  graphs: Graph[];
}

const Legend = styled.div`
  position: absolute;
  color: var(--color-recorded);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  font-family: FavoritMono, Courier, monospace;
  width: 100%;
  top: 0;

  ul {
    list-style: none;
    margin: 0 0 0.1rem;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    li {
      display: inline-block;

      &:first-child {
        color: var(--color-assistance);

        hr {
          border-style: dashed;
          margin-left: 0;
          border-top: 0;
        }
      }
    }
  }

  hr {
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    margin: 0 0.4rem;
    border-width: 2px;
    border-color: var(--color-recorded);
  }
`;

const CampaignButton = styled.div`
  display: flex;
  margin-top: 0.5rem;
  font-size: 0.9rem;

  a {
    display: block;
    padding: 1rem 0.5rem;
    font-size: 0.8rem;
    flex: 1 0 0;
    text-align: center;
    color: var(--color-black);
    transition: border-color 0.2s;
    border-bottom: 4px solid transparent;

    &.-active {
      font-weight: bold;
      border-bottom-color: var(--color-alternate);
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CampaignGraphs: React.FC<CampaignGraphProps> = ({ graphs }) => {
  const [activeIndex, setIndex] = useState(0);

  function handleIndexChange(e: React.MouseEvent<HTMLAnchorElement>, index: number) {
    e.preventDefault();
    setIndex(index);
  }

  return (
    <Wrapper>
      {activeIndex === 0 && <ReachImpressionsLegend />}
      <CampaignGraph data={graphs[activeIndex]} />
      <CampaignButton>
        {(graphs || []).map((graph, index) => (
          <a
            href="#index"
            className={activeIndex === index ? '-active' : ''}
            onClick={(e) => handleIndexChange(e, index)}
            key={`Campaign-${index}`}>
            {graph.meta?.name}
          </a>
        ))}
      </CampaignButton>
    </Wrapper>
  );
};

const ReachImpressionsLegend: React.FC = () => (
  <Legend>
    <ul>
      <li>
        <hr />
        Predicted
      </li>
      <li>
        <hr />
        Delivered
      </li>
    </ul>
  </Legend>
);

export default CampaignGraphs;
