import styled from 'styled-components';
import Card from '../../components/Card';
import Grid from '../../components/Layout/Grid';
import GridItem from '../../components/Layout/GridItem';
import CampaignSearch from './CampaignSearch/CampaignSearch';

const Styled = styled.div`
  position: relative;
  z-index: 2;
`;

function CampaignSearchGrid() {
  return (
    <Styled>
      <Grid>
        <GridItem $columns={4}>
          <Card type="sunburst" name="" showOverflow={1}>
            <CampaignSearch />
          </Card>
        </GridItem>
      </Grid>
    </Styled>
  );
}

export default CampaignSearchGrid;
