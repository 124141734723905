// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/feedback.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FeedbackRequest, FeedbackResponse } from "./feedback_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service recommenderpb.Feedback
 */
export const Feedback = {
  typeName: "recommenderpb.Feedback",
  methods: {
    /**
     * @generated from rpc recommenderpb.Feedback.Upload
     */
    upload: {
      name: "Upload",
      I: FeedbackRequest,
      O: FeedbackResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

