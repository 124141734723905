const firebaseConfig = {
  apiKey: 'AIzaSyCdp4vx2CAaWckRv6tfpClUfcWsJc6nESY',
  authDomain: 'mad-labs-rnd.firebaseapp.com',
  projectId: 'mad-labs-rnd',
  storageBucket: 'mad-labs-rnd.appspot.com',
  messagingSenderId: '993768862495',
  appId: '1:993768862495:web:60fb7ebcd95fff315bb62a'
};

export default firebaseConfig;
