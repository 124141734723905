import * as Plot from '@observablehq/plot';

interface Options {
  asLines?: boolean;
  cumulative?: boolean;
  facetBy?: string;
  normalize?: boolean;
  normalizeBasis?: Plot.NormalizeBasisName;
  showLineLabels?: boolean;
  yKey?: string;
}

const defaultOptions = {
  asLines: false,
  cumulative: false,
  facetBy: undefined,
  normalize: false,
  normalizeBasis: 'median' as Plot.NormalizeBasisName,
  showLineLabels: false,
  yKey: 'value2'
};

export default function generateHistogram<T>(
  data: T[],
  options: Options = defaultOptions
): Plot.Markish[] {
  options = { ...defaultOptions, ...options };
  const marks: Plot.Markish[] = [];

  const _options: Plot.BinXInputs<Plot.RectYOptions> = {
    cumulative: options.cumulative,
    fill: 'category',
    fy: options.facetBy,
    mixBlendMode: 'mulitply',
    tip: {
      format: {
        facet: false,
        fillOpacity: false,
        fy: false,
        stroke: false,
        strokeWidth: false,
        strokeOpacity: false
      }
    },
    x: options.yKey,
    z: 'category'
  };

  const _lineOptions: Plot.BinXInputs<Plot.LineYOptions> = {
    stroke: 'category',
    strokeOpacity: (d) => {
      return d.every((x: any) => x.category.includes('Net')) ? 0.6 : 1;
    },
    ..._options,
    fill: null
  };

  if (options.asLines) {
    if (options.normalize) {
      marks.push(
        Plot.lineY(
          data,
          Plot.normalizeY(options.normalizeBasis, Plot.binX({ y: 'count' }, _lineOptions))
        )
      );
    } else {
      marks.push(Plot.lineY(data, Plot.binX({ y: 'count' }, _lineOptions)));
    }

    if (options.showLineLabels) {
      if (options.normalize) {
        marks.push(
          Plot.text(
            data,
            Plot.selectLast(
              Plot.normalizeY(
                options.normalizeBasis,
                Plot.binX({ y: 'count' }, {
                  ..._lineOptions,
                  fill: null,
                  text: 'category'
                } as Plot.BinXInputs<Plot.LineYOptions>)
              )
            )
          )
        );
      } else {
        marks.push(
          Plot.text(
            data,
            Plot.selectLast(
              Plot.binX({ y: 'count' }, {
                ..._options,
                fill: null,
                text: 'category'
              } as Plot.BinXInputs<Plot.LineYOptions>)
            )
          )
        );
      }
    }
  } else {
    if (options.normalize) {
      marks.push(
        Plot.rectY(
          data,
          Plot.normalizeY(options.normalizeBasis, Plot.binX({ y: 'count' }, _options))
        )
      );
    } else {
      marks.push(Plot.rectY(data, Plot.binX({ y: 'count' }, _options)));
    }
  }

  if (options.facetBy) {
    marks.push(
      ...[
        // Plot.frame({
        //   rx: 8,
        //   ry: 8,
        //   stroke: 'var(--color-border)'
        // }),

        Plot.text(
          data,
          Plot.selectLast({
            text: options.facetBy,
            fy: options.facetBy,
            frameAnchor: 'top-right',
            dx: -4,
            dy: 6
          })
        )
      ]
    );
  }

  return marks;
}
