import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { debouncedRepredict, repredictAtom } from '../../../Campaign/state/campaign';
import { existingExprAtom, wipExprAtom } from '../../../Campaign/state/campaign_meta';
import Loading from '../../../components/Loading/LoadingDots';
import Icon from '../../../components/icons/Icon';
import withClient from '../../../proto/with_client';
import { BooleanExpression } from '../../../protos/recommender/boolean_expression_pb';
import { Meta } from '../../../protos/recommender/meta_connect';
import { existingSegmentByIdAtom, refetchAtom, segmentByIdAtom } from '../../state/segments';
import { addSegmentsByTextAtom } from '../../state/suggest';
import SegmentIdentity from '../SegmentIdentity';
import SegmentTextSearch from '../SegmentTextSearch';
import BooleanExpressionOp from './BooleanExpressionOp';
import DetailRunSwaps from './DetailSwaps';

const Container = styled.div`
  width: 100%;
`;

const ToggleBar = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
`;

const Header = styled.header`
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0.2rem;
  text-transform: capitalize;

  svg {
    margin-left: 0.5rem;
  }
`;

const ExistingToggle = styled.div<{ $suggested?: number }>`
  cursor: pointer;
  font-weight: bold;
  margin: 0.4rem 0;
  text-transform: uppercase;
  color: ${(props) => (props.$suggested ? 'var(--color-assistance)' : 'var(--color-recorded)')};
  opacity: 0.8;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }
`;

const Styled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  > li {
    align-items: center;
    display: flex;
  }
`;

const BooleanExpressionIds: React.FC<{ $position: number[] }> = ({ $position }) => {
  const [byId] = useAtom(segmentByIdAtom);
  const [existingById] = useAtom(existingSegmentByIdAtom);
  const [summarizedName, setSummarizedName] = React.useState<string | null>(null);
  const client = withClient(Meta);
  const [showExisting, setShowExisting] = useState(false);
  const [showSwappable, setShowSwappable] = useState(true);
  const [showAll, setShowAll] = useState(true);
  const [existingExpr] = useAtom(existingExprAtom);
  const [wipExpr, setWipExpr] = useAtom(wipExprAtom);
  const [enabled, setEnabled] = useState(true);
  const [suggestedIds, setSuggestedIds] = useState<string[]>([]);
  const [selectedExistingIds, setSelectedExistingIds] = useState<string[]>([]);
  const [selectedSuggestedIds, setSelectedSuggestedIds] = useState<string[]>([]);

  const [, _repredict] = useAtom(repredictAtom);
  const [, addSegmentsByText] = useAtom(addSegmentsByTextAtom);
  const repredict = debouncedRepredict(_repredict);
  const [, refetch] = useAtom(refetchAtom);

  let expr: BooleanExpression | undefined = existingExpr;
  for (let i = 0; i < $position.length; i++) {
    if (expr?.children[$position[i]]) {
      expr = expr?.children[$position[i]];
    }
  }
  const existingIds = expr?.ids.filter((id) => existingById[id]) ?? [];
  const swapIds =
    expr?.ids
      .filter((id) => existingById[id] !== undefined && byId[id].swapTo)
      .map((id) => byId[id].swapTo!.id) ?? [];
  const [selectedSuggestedSwapIds, setSelectedSuggestedSwapIds] = useState<string[]>(
    Array.from(new Set(swapIds))
  );
  const names = existingIds.map((id) => byId[id]?.name);

  useEffect(() => {
    client.summaryName({ text: names.join('\n') }).then((response) => {
      setSummarizedName(response.text);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swapIds.length > 0) return;
    addSegmentsByText(names.join('\n'), names, existingIds, 1).then((response) => {
      let unique: Set<string> = new Set();
      setSuggestedIds((r) => {
        unique = new Set([...r, ...(response ?? [])]);
        return Array.from(unique);
      });
      setSelectedSuggestedIds((r) => {
        unique = new Set([...r, ...(response ?? [])]);
        return Array.from(unique);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshSelections() {
    const derived = wipExpr?.clone();
    let expr: BooleanExpression | undefined = derived;
    for (let i = 0; i < $position.length; i++) {
      if (expr?.children[$position[i]]) {
        expr = expr?.children[$position[i]];
      }
    }
    if (!expr) return;

    expr!.ids = Array.from(
      new Set([...selectedExistingIds, ...selectedSuggestedSwapIds, ...selectedSuggestedIds])
    );
    setWipExpr(derived);
    repredict().then(refetch);
  }

  useEffect(
    refreshSelections,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSuggestedIds, selectedSuggestedSwapIds, selectedExistingIds]
  );

  useEffect(() => {
    if (enabled) {
      setSelectedSuggestedIds(suggestedIds);
      setSelectedSuggestedSwapIds(swapIds);
    } else {
      setSelectedSuggestedIds([]);
      setSelectedExistingIds([]);
      setSelectedSuggestedSwapIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  function toggleAll() {
    if (showAll) {
      setShowAll(false);
      setShowExisting(false);
    } else {
      setShowAll(true);
    }
  }

  function toggleSelected(
    id: string,
    selectedIds: string[],
    setSelectedIds: (ids: React.SetStateAction<string[]>) => void
  ) {
    let newIds: string[] = [];
    if (selectedIds.includes(id)) {
      newIds = selectedIds.filter((x) => x !== id);
    } else {
      newIds = [...selectedIds, id];
    }
    const unique = new Set(newIds);
    setSelectedIds(Array.from(unique));
  }

  const toggleExistingSelected = (id: string) =>
    toggleSelected(id, selectedExistingIds, setSelectedExistingIds);
  const toggleSuggestedSelected = (id: string) =>
    toggleSelected(id, selectedSuggestedIds, setSelectedSuggestedIds);
  const toggleSwapSelection = (id: string) =>
    toggleSelected(id, selectedSuggestedSwapIds, setSelectedSuggestedSwapIds);

  if (!expr || summarizedName === null) return <Loading />;

  if (!showAll) {
    return (
      <Container>
        <Header onClick={toggleAll}>
          {summarizedName}
          <Icon name={showExisting ? 'chevron-down' : 'chevron-up'} />
        </Header>
      </Container>
    );
  }

  const uniqueSwapIds = Array.from(new Set(swapIds));

  return (
    <Container>
      <Header onClick={toggleAll}>
        {summarizedName}
        <Icon name={showAll ? 'chevron-down' : 'chevron-up'} />
      </Header>
      <ToggleBar>
        {existingIds.length > 0 && (
          <ExistingToggle
            onClick={() => {
              setShowExisting(!showExisting);
              setShowSwappable(false);
            }}>
            {showExisting ? 'Hide' : ''} {existingIds.length} Current Segments
          </ExistingToggle>
        )}
        {swapIds.length > 0 && (
          <ExistingToggle
            $suggested={1}
            onClick={() => {
              setShowSwappable(!showSwappable);
              setShowExisting(false);
            }}>
            {showSwappable ? 'Hide' : ''} {new Set(swapIds).size} Suggested Segments
          </ExistingToggle>
        )}
        <DetailRunSwaps ids={existingIds} op={expr.op} />
        <ExistingToggle onClick={() => setEnabled(!enabled)}>
          {enabled ? 'Disable' : 'Enable'} All
        </ExistingToggle>
      </ToggleBar>
      <Styled>
        {showExisting &&
          existingIds.map((id) => (
            <li
              key={`existing-${$position.join(',')}-${id}`}
              onClick={() => toggleExistingSelected(id)}>
              <SegmentIdentity
                $existing={true}
                $id={id}
                $selected={selectedExistingIds.includes(id)}
              />
              {existingIds.length > 0 && id !== existingIds[existingIds.length - 1] && (
                <BooleanExpressionOp $text={expr.op} $forSuggestions={1} />
              )}
            </li>
          ))}
      </Styled>
      <Styled>
        {showSwappable &&
          uniqueSwapIds.map((id) => (
            <li key={`swap-${$position.join(',')}-${id}`} onClick={() => toggleSwapSelection(id)}>
              <SegmentIdentity
                $existing={false}
                $id={id}
                $selected={selectedSuggestedSwapIds.includes(id)}
              />
              {swapIds.length > 0 && id !== uniqueSwapIds[uniqueSwapIds.length - 1] && (
                <BooleanExpressionOp $text={expr.op} $forSuggestions={1} />
              )}
            </li>
          ))}
      </Styled>
      <Styled>
        {suggestedIds.map((id) => (
          <li
            key={`suggested-${$position.join(',')}-${id}`}
            onClick={() => toggleSuggestedSelected(id)}>
            <BooleanExpressionOp $text={expr.op} $forSuggestions={1} />
            <SegmentIdentity
              $existing={false}
              $id={id}
              $selected={selectedSuggestedIds.includes(id)}
            />
          </li>
        ))}
      </Styled>
      {enabled && (
        <SegmentTextSearch
          existingSelectedIdsInRun={Array.from(
            new Set([...selectedExistingIds, ...selectedSuggestedSwapIds, ...selectedSuggestedIds])
          )}
          onSearch={(r) => setSuggestedIds((x) => [...x, ...r])}
          existingPredictedIds={suggestedIds}
        />
      )}
    </Container>
  );
};

export default BooleanExpressionIds;
