// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/predict.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Graph } from "../graph/graph_pb";
import { Pricing } from "./meta_pb";
import { BooleanExpression } from "./boolean_expression_pb";

/**
 * @generated from message recommenderpb.CampaignRequest
 */
export class CampaignRequest extends Message<CampaignRequest> {
  /**
   * @generated from field: string campaign_id = 1;
   */
  campaignId = "";

  /**
   * @generated from field: google.protobuf.Timestamp current_time = 2;
   */
  currentTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp predict_using_data_before = 3;
   */
  predictUsingDataBefore?: Timestamp;

  /**
   * @generated from field: int32 booked_impressions = 4;
   */
  bookedImpressions = 0;

  constructor(data?: PartialMessage<CampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.CampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_time", kind: "message", T: Timestamp },
    { no: 3, name: "predict_using_data_before", kind: "message", T: Timestamp },
    { no: 4, name: "booked_impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignRequest {
    return new CampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignRequest {
    return new CampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignRequest {
    return new CampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignRequest | PlainMessage<CampaignRequest> | undefined, b: CampaignRequest | PlainMessage<CampaignRequest> | undefined): boolean {
    return proto3.util.equals(CampaignRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.CampaignPrediction
 */
export class CampaignPrediction extends Message<CampaignPrediction> {
  /**
   * @generated from field: repeated graph.Graph graphs = 1;
   */
  graphs: Graph[] = [];

  /**
   * @generated from field: int32 impressions_at_current_time = 2;
   */
  impressionsAtCurrentTime = 0;

  /**
   * @generated from field: double fulfillment_percentage = 3;
   */
  fulfillmentPercentage = 0;

  /**
   * @generated from field: int32 additional_reach = 4;
   */
  additionalReach = 0;

  /**
   * @generated from field: int32 total_reach = 5;
   */
  totalReach = 0;

  /**
   * @generated from field: recommenderpb.Pricing existing_pricing = 6;
   */
  existingPricing?: Pricing;

  /**
   * @generated from field: recommenderpb.Pricing wip_pricing = 7;
   */
  wipPricing?: Pricing;

  constructor(data?: PartialMessage<CampaignPrediction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.CampaignPrediction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "graphs", kind: "message", T: Graph, repeated: true },
    { no: 2, name: "impressions_at_current_time", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fulfillment_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "additional_reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "existing_pricing", kind: "message", T: Pricing },
    { no: 7, name: "wip_pricing", kind: "message", T: Pricing },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignPrediction {
    return new CampaignPrediction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignPrediction {
    return new CampaignPrediction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignPrediction {
    return new CampaignPrediction().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignPrediction | PlainMessage<CampaignPrediction> | undefined, b: CampaignPrediction | PlainMessage<CampaignPrediction> | undefined): boolean {
    return proto3.util.equals(CampaignPrediction, a, b);
  }
}

/**
 * @generated from message recommenderpb.SegmentPrediction
 */
export class SegmentPrediction extends Message<SegmentPrediction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated graph.Graph graphs = 3;
   */
  graphs: Graph[] = [];

  constructor(data?: PartialMessage<SegmentPrediction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SegmentPrediction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "graphs", kind: "message", T: Graph, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SegmentPrediction {
    return new SegmentPrediction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SegmentPrediction {
    return new SegmentPrediction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SegmentPrediction {
    return new SegmentPrediction().fromJsonString(jsonString, options);
  }

  static equals(a: SegmentPrediction | PlainMessage<SegmentPrediction> | undefined, b: SegmentPrediction | PlainMessage<SegmentPrediction> | undefined): boolean {
    return proto3.util.equals(SegmentPrediction, a, b);
  }
}

/**
 * @generated from message recommenderpb.SegmentsRequest
 */
export class SegmentsRequest extends Message<SegmentsRequest> {
  /**
   * @generated from field: repeated string segment_ids = 1;
   */
  segmentIds: string[] = [];

  /**
   * @generated from field: string campaign_id = 2;
   */
  campaignId = "";

  /**
   * @generated from field: google.protobuf.Timestamp current_time = 3;
   */
  currentTime?: Timestamp;

  /**
   * @generated from field: int32 booked_impressions = 4;
   */
  bookedImpressions = 0;

  constructor(data?: PartialMessage<SegmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SegmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "current_time", kind: "message", T: Timestamp },
    { no: 4, name: "booked_impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SegmentsRequest {
    return new SegmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SegmentsRequest {
    return new SegmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SegmentsRequest {
    return new SegmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SegmentsRequest | PlainMessage<SegmentsRequest> | undefined, b: SegmentsRequest | PlainMessage<SegmentsRequest> | undefined): boolean {
    return proto3.util.equals(SegmentsRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.BooleanExpressionRequest
 */
export class BooleanExpressionRequest extends Message<BooleanExpressionRequest> {
  /**
   * @generated from field: recommenderpb.BooleanExpression boolean_expression = 1;
   */
  booleanExpression?: BooleanExpression;

  /**
   * @generated from field: string campaign_id = 2;
   */
  campaignId = "";

  /**
   * @generated from field: google.protobuf.Timestamp current_time = 3;
   */
  currentTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp predict_using_data_before = 4;
   */
  predictUsingDataBefore?: Timestamp;

  /**
   * @generated from field: int32 booked_impressions = 5;
   */
  bookedImpressions = 0;

  constructor(data?: PartialMessage<BooleanExpressionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.BooleanExpressionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "boolean_expression", kind: "message", T: BooleanExpression },
    { no: 2, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "current_time", kind: "message", T: Timestamp },
    { no: 4, name: "predict_using_data_before", kind: "message", T: Timestamp },
    { no: 5, name: "booked_impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BooleanExpressionRequest {
    return new BooleanExpressionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BooleanExpressionRequest {
    return new BooleanExpressionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BooleanExpressionRequest {
    return new BooleanExpressionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BooleanExpressionRequest | PlainMessage<BooleanExpressionRequest> | undefined, b: BooleanExpressionRequest | PlainMessage<BooleanExpressionRequest> | undefined): boolean {
    return proto3.util.equals(BooleanExpressionRequest, a, b);
  }
}

