// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file feed/time_series_feed.proto (package feed, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message feed.TimeSeriesFeed
 */
export class TimeSeriesFeed extends Message<TimeSeriesFeed> {
  /**
   * @generated from field: repeated feed.TimeSeriesFeedData data = 1;
   */
  data: TimeSeriesFeedData[] = [];

  constructor(data?: PartialMessage<TimeSeriesFeed>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "feed.TimeSeriesFeed";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "message", T: TimeSeriesFeedData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeSeriesFeed {
    return new TimeSeriesFeed().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeSeriesFeed {
    return new TimeSeriesFeed().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeSeriesFeed {
    return new TimeSeriesFeed().fromJsonString(jsonString, options);
  }

  static equals(a: TimeSeriesFeed | PlainMessage<TimeSeriesFeed> | undefined, b: TimeSeriesFeed | PlainMessage<TimeSeriesFeed> | undefined): boolean {
    return proto3.util.equals(TimeSeriesFeed, a, b);
  }
}

/**
 * @generated from message feed.TimeSeriesFeedData
 */
export class TimeSeriesFeedData extends Message<TimeSeriesFeedData> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * Used for segmenting data
   *
   * @generated from field: string category = 3;
   */
  category = "";

  constructor(data?: PartialMessage<TimeSeriesFeedData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "feed.TimeSeriesFeedData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeSeriesFeedData {
    return new TimeSeriesFeedData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeSeriesFeedData {
    return new TimeSeriesFeedData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeSeriesFeedData {
    return new TimeSeriesFeedData().fromJsonString(jsonString, options);
  }

  static equals(a: TimeSeriesFeedData | PlainMessage<TimeSeriesFeedData> | undefined, b: TimeSeriesFeedData | PlainMessage<TimeSeriesFeedData> | undefined): boolean {
    return proto3.util.equals(TimeSeriesFeedData, a, b);
  }
}

