// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file feed/numeric_feed.proto (package feed, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message feed.NumericFeed
 */
export class NumericFeed extends Message<NumericFeed> {
  /**
   * @generated from field: repeated feed.NumericFeedData data = 1;
   */
  data: NumericFeedData[] = [];

  constructor(data?: PartialMessage<NumericFeed>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "feed.NumericFeed";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "message", T: NumericFeedData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NumericFeed {
    return new NumericFeed().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NumericFeed {
    return new NumericFeed().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NumericFeed {
    return new NumericFeed().fromJsonString(jsonString, options);
  }

  static equals(a: NumericFeed | PlainMessage<NumericFeed> | undefined, b: NumericFeed | PlainMessage<NumericFeed> | undefined): boolean {
    return proto3.util.equals(NumericFeed, a, b);
  }
}

/**
 * @generated from message feed.NumericFeedData
 */
export class NumericFeedData extends Message<NumericFeedData> {
  /**
   * On a line chart, this is X
   *
   * @generated from field: double value = 1;
   */
  value = 0;

  /**
   * Useful for line charts or bar charts that utilize a numerical x-axis
   * On a line chart, this is Y
   *
   * @generated from field: double value2 = 2;
   */
  value2 = 0;

  /**
   * Useful for extra data, such as Y1 (baseline in area graph) or
   * Y2 (disables implicit stacking in bar graphs)
   *
   * @generated from field: double value3 = 3;
   */
  value3 = 0;

  /**
   * Useful for extra data, such as Y2 (topline in area graph)
   *
   * @generated from field: double value4 = 4;
   */
  value4 = 0;

  /**
   * Used for segmenting data
   *
   * @generated from field: string category = 5;
   */
  category = "";

  constructor(data?: PartialMessage<NumericFeedData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "feed.NumericFeedData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "value2", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "value3", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "value4", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NumericFeedData {
    return new NumericFeedData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NumericFeedData {
    return new NumericFeedData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NumericFeedData {
    return new NumericFeedData().fromJsonString(jsonString, options);
  }

  static equals(a: NumericFeedData | PlainMessage<NumericFeedData> | undefined, b: NumericFeedData | PlainMessage<NumericFeedData> | undefined): boolean {
    return proto3.util.equals(NumericFeedData, a, b);
  }
}

