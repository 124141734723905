import * as Plot from '@observablehq/plot';
import type Certainty from './certainty';

interface Options {
  horizontal?: boolean;
  xKey?: string;
  yKey?: string;
  y2Key?: string;
  groupBy?: string;
  tip?: boolean;
}

const defaultOptions = {
  horizontal: false,
  xKey: 'value',
  yKey: 'value2',
  tip: false
};

export default function generateBars(data: Certainty[], options: Options): Plot.Markish[] {
  options = { ...defaultOptions, ...options };
  const marks: Plot.Markish = [];

  const inputOptions = {
    x: options.xKey,
    y: options.yKey,
    fill: 'category'
  };

  if (options.y2Key?.length) {
    (inputOptions as any).y2 = options.y2Key;
  }

  if (options.horizontal) {
    if (options.groupBy?.length) {
      marks.push(
        Plot.barX(data, {
          ...inputOptions,
          x: 'category',
          fill: 'category',
          fy: options.groupBy,
          tip: options.tip ? 'y' : undefined
        })
      );
    } else {
      marks.push(
        Plot.barX(data, {
          ...inputOptions,
          tip: options.tip ? 'y' : undefined
        })
      );
    }
    marks.push(Plot.ruleX([0]));
  } else {
    if (options.groupBy?.length) {
      marks.push(
        Plot.barY(data, {
          ...inputOptions,
          x: 'category',
          fill: 'category',
          fx: options.groupBy,
          tip: options.tip ? 'x' : undefined
        })
      );
    } else {
      marks.push(
        Plot.barY(data, {
          ...inputOptions,
          tip: options.tip ? 'x' : undefined
        })
      );
    }

    marks.push(Plot.ruleY([0], { stroke: 'var(--color-border)' }));
  }

  return marks;
}
