import { ReactNode } from 'react';
import styled from 'styled-components';

interface GridProps {
  children?: ReactNode;
}

const Grid = styled.section`
  align-items: center;
  display: grid;
  gap: 30px 0px;
  grid-template-columns: var(
    --template-columns,
    repeat(auto-fill, minmax(var(--grid-column-width), 1fr))
  );
  grid-auto-rows: var(--template-rows, minmax(var(--grid-row-height), auto));
  padding: 0 0 3rem;
`;

const GridComponent: React.FC<GridProps> = ({ children }) => {
  return <Grid>{children}</Grid>;
};

export default GridComponent;
