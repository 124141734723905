import React from 'react';
import styled from 'styled-components';
import { BooleanExpression } from '../../../protos/recommender/boolean_expression_pb';
import BooleanExpressionIds from './BooleanExpressionIds';
import BooleanExpressionOp from './BooleanExpressionOp';

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 1.2rem;

  & & {
    margin-left: 5px;
    flex: 1 0 0;
  }

  > li {
    display: flex;
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    h5 ~ & {
      margin-left: 0;
    }
  }
`;

const BooleanExpressionIdentity: React.FC<{
  $expr: BooleanExpression;
  $position?: number[];
}> = ({ $position = [], $expr }) => {
  if ($expr.children.length === 0) {
    return (
      <Wrapper>
        <BooleanExpressionIds $position={[...$position, 0]} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {$expr.ids.length > 0 && (
        <li>
          <BooleanExpressionIds $position={[...$position]} />
        </li>
      )}

      {$expr.children.map((child, i) => (
        <li key={`${i}-${$position.join(' ')}`}>
          {$expr.ids.length > 0 && (
            <BooleanExpressionOp $text={$expr.op} $forSuggestions={0} $visible={0} />
          )}
          <BooleanExpressionOp
            $text={$expr.op}
            $forSuggestions={0}
            $visible={child.children.length === 0 ? 1 : 0}
          />
          <BooleanExpressionIdentity $expr={child} $position={[...$position, i]} />
        </li>
      ))}
    </Wrapper>
  );
};

export default BooleanExpressionIdentity;
