import * as d3 from 'd3';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  campaignNameAtom,
  existingExprAtom,
  fulfillmentPercentageAtom,
  wipExprAtom
} from '../../../Campaign/state/campaign_meta';
import Icon from '../../../components/icons/Icon';
import Modal from '../../../components/Layout/Modal';
import withClient from '../../../proto/with_client';
import { BooleanExpression } from '../../../protos/recommender/boolean_expression_pb';
import { Meta } from '../../../protos/recommender/meta_connect';
import { segmentByIdAtom } from '../../state/segments';

const Content = styled.div`
  h2,
  h3 {
    text-align: center;
  }

  h2 {
    font-size: 3rem;

    &.positive {
      color: var(--color-assistance);
    }
  }

  h3 {
    color: var(--color-recorded);
    font-size: 1rem;
  }

  button {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    text-transform: uppercase;
    color: var(--color-focus);
    margin: 0.3rem 0 0.4rem;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.04);
    }

    &.-title {
      div:nth-child(3) {
        h2 {
          color: var(--color-assistance);
        }
      }
    }

    &.-download {
      justify-content: end;
      text-align: right;
      padding: 0.2rem;
      background: none;
    }

    div {
      flex: 1 0 10%;

      &:nth-child(1),
      &:nth-child(3) {
        flex-basis: 40%;
      }

      &:nth-child(2) {
        color: var(--color-recorded);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.7rem;
      }

      &:nth-child(2),
      &:nth-child(4) {
        text-align: center;
      }

      &:nth-child(4) {
        font-weight: bold;
      }

      &.positive {
        color: var(--color-assistance);
      }
    }
  }
`;

const Styled = styled.div`
  text-align: right;

  button {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    text-transform: uppercase;
    color: var(--color-focus);
    margin: 0.3rem 0 0.4rem;
    font-weight: bold;
    font-size: 0.7rem;
    cursor: pointer;
  }
`;

const DetailSwaps: React.FC<{ ids: string[]; op?: string | undefined }> = ({
  ids,
  op = undefined
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  const [segmentById] = useAtom(segmentByIdAtom);
  const [percentage] = useAtom(fulfillmentPercentageAtom);
  const mdmIds = ids.map((id) => segmentById[id].swapTo?.id ?? '').filter((id) => id.length);
  const [currentReach, setCurrentReach] = useState(0);
  const [suggestedReach, setNewReach] = useState(0);
  const [didCalculate, setDidCalculate] = useState(false);
  const [campaignName] = useAtom(campaignNameAtom);
  const [existingExpr] = useAtom(existingExprAtom);
  const [newExpr] = useAtom(wipExprAtom);

  const handleClose = () => setIsOpen(false);

  const client = withClient(Meta);

  useEffect(() => {
    if (shouldOpen && !didCalculate) {
      try {
        client
          .reachByBooleanExpression({
            expr: op ? new BooleanExpression({ ids, op }) : existingExpr
          })
          .then((resp) => setCurrentReach(resp.reach));
        client
          .reachByBooleanExpression({
            expr: op ? new BooleanExpression({ ids: mdmIds, op }) : newExpr
          })
          .then((resp) => setNewReach(resp.reach));
      } catch (error) {
        console.warn(error);
      } finally {
        setDidCalculate(true);
      }
    }
    setIsOpen(shouldOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpen, setIsOpen]);

  function format(value: number): string {
    if (value === 0) return '0';
    value = value * percentage;
    if (value < 1 && value > 0) return d3.format('.1f')(value);
    return d3.format('.2s')(value);
  }

  function createCSV() {
    const csvRows = [];
    const headers = [
      'Current Name',
      'Current CPM',
      'Current Reach',
      'New Name',
      'New CPM',
      'New Reach',
      'Reach Change'
    ];
    csvRows.push(headers.join(','));

    for (const id of ids) {
      const row: string[] = [];
      row.push(segmentById[id].name);
      row.push(segmentById[id].cpm.toString());
      row.push(segmentById[id].reach.toString());
      row.push(segmentById[id].swapTo?.name ?? '');
      row.push(segmentById[id].swapTo?.cpm.toString() ?? '');
      row.push(segmentById[id].swapTo?.reach.toString() ?? '');
      row.push(
        (
          ((segmentById[id].swapTo?.reach ?? 0) - segmentById[id].reach) /
          (segmentById[id].reach ?? 1)
        ).toString()
      );
      csvRows.push(row.join(','));
    }

    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], { type: 'text/csv' });

    // Step 4: Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${campaignName}.csv`;

    // Step 5: Trigger the download
    document.body.appendChild(link); // Append link to the body
    link.click();

    // Step 6: Clean up
    document.body.removeChild(link); // Remove the link
    URL.revokeObjectURL(url); // Release object URL
  }

  return (
    <Styled>
      <button onClick={() => setShouldOpen(!shouldOpen)}>Swap Detail</button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <Content>
          <div className="row -download">
            <button onClick={createCSV}>
              <Icon name="download" /> Download CSV
            </button>
          </div>
          <div className="row -title">
            <div>
              <h3>Current Reach</h3>
              <h2>{didCalculate && format(currentReach)}</h2>
            </div>
            <div></div>
            <div>
              <h3>New Reach</h3>
              <h2>{didCalculate && format(suggestedReach)}</h2>
            </div>
            <div>
              <h3>Change</h3>
              <h2 className={suggestedReach - currentReach > 0 ? 'positive' : 'negative'}>
                {didCalculate && suggestedReach - currentReach > 0 ? '+' : ''}
                {didCalculate && format(suggestedReach - currentReach)}
              </h2>
            </div>
          </div>
          {ids.map((id) => (
            <div className="row -segment" key={id}>
              <div>
                {segmentById[id].name}
                <br />
                {d3.format('$.2f')(segmentById[id].cpm)} | {format(segmentById[id].reach ?? 0)}{' '}
                Reach
              </div>
              <div>to</div>
              <div>
                {segmentById[id].swapTo?.name}
                <br />
                {d3.format('$.2f')(segmentById[id].swapTo?.cpm ?? 0)} |{' '}
                {format(segmentById[id].swapTo?.reach ?? 0)} Reach
              </div>
              <div
                className={
                  (segmentById[id].swapTo?.reach ?? 0) - segmentById[id].reach > 0
                    ? 'positive'
                    : 'negative'
                }>
                {(segmentById[id].swapTo?.reach ?? 0) - segmentById[id].reach > 0 ? '+' : ''}
                {d3.format('.0%')(
                  ((segmentById[id].swapTo?.reach ?? 0) - segmentById[id].reach) /
                    (segmentById[id].reach ?? 1)
                )}
              </div>
            </div>
          ))}
        </Content>
      </Modal>
    </Styled>
  );
};

export default DetailSwaps;
