import { useAtom, type PrimitiveAtom } from 'jotai';
import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { repredictAtom } from '../state/campaign';
import { campaignEndAtom, campaignStartAtom } from '../state/campaign_meta';

const Styled = styled.header`
  h5 {
    display: inline-block;
    margin-right: 0.4rem;
    margin-bottom: 0;
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    accent-color: var(--color-alternate);
    background: var(--color-alternate);
    border-color: transparent;
    border-radius: 30px;
    color: transparent;
    cursor: pointer;
    height: 4px;
    width: 100%;
    margin: 0 0 1rem 0;
    padding: 0;
    transition: height 0.2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: var(--color-emphasis);
      border-radius: 20px;
      cursor: pointer;
      height: 18px;
      opacity: 1;
      transition: opacity 0.2s;
      width: 18px;
      border: 2px solid var(--color-white);
    }
  }
`;

const TimeSlider: React.FC<{ $label: string; $atom: PrimitiveAtom<Date> }> = ({
  $label,
  $atom
}) => {
  const [, repredict] = useAtom(repredictAtom);
  const [startDate] = useAtom(campaignStartAtom);
  const [endDate] = useAtom(campaignEndAtom);
  const [currentTime, setCurrentTime] = useAtom($atom);
  const start = startDate?.getTime() ?? 0;
  const end = Math.min(endDate?.getTime() ?? new Date().getTime(), new Date().getTime());

  const debouncedPredict = useDebouncedCallback(repredict, 1000);

  if (start === 0 || end === 0) return null;

  return (
    <Styled>
      <h5>{$label}</h5>
      {(currentTime ?? new Date()).toLocaleString(undefined, {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        hour12: true,
        year: '2-digit'
      })}
      <input
        type="range"
        min={start}
        max={end}
        value={currentTime?.getTime() || new Date().getTime()}
        step={1000 * 60 * 60}
        onChange={(e) => {
          setCurrentTime(new Date(Number(e.target.value)));
          debouncedPredict();
        }}
      />
    </Styled>
  );
};

export default TimeSlider;
