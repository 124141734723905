import type { ScaleOptions } from '@observablehq/plot';

export const colorScheme = [
  // Default (gray)
  'var(--color-recorded)',
  // Pink
  'var(--color-assistance)',
  // Purple
  '#E8BEFE',
  // Yellow
  '#ffcc38',
  // Red
  '#FF5F4A',
  // Green
  '#00EBB0',
  // Purple
  '#E8BEFE'
];
export const globalStyles = {
  style: {
    background: 'none',
    fontFamily: 'FavoritMono, Courier, monospace',
    fontSize: '9px'
  },
  color: {
    type: 'categorical',
    range: colorScheme
  } as ScaleOptions
};
