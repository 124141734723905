import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0%, 80%, 100% {
    transform: scale(0)
  }
  40% {
    transform: scale(1)
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  background-color: var(--color-alternate);
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  margin: 0 0.1em;
  animation: ${pulse} 1.3s infinite ease-in-out both;
  animation-delay: -0.32s;

  // Inspired by
  // https://react-loading-indicators.netlify.app/
  &:nth-child(2) {
    background-color: #ffcc3b;
    animation-delay: -0.16s;
  }

  &:nth-child(3) {
    background-color: #00ebb0;
    animation-delay: 0s;
  }
`;

const Loading = () => (
  <LoadingIndicator>
    <Dot />
    <Dot />
    <Dot />
  </LoadingIndicator>
);

export default Loading;
