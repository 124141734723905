import * as Plot from '@observablehq/plot';

interface Options {
  showRegressionYPerCategory?: boolean;
  showRegressionY?: boolean;
  xKey?: string;
  yKey?: string;
}

const defaultOptions = {
  showRegressionYPerCategory: false,
  showRegressionY: false,
  xKey: 'value',
  yKey: 'value2'
};

export default function generateScatterPlot<T>(
  data: T[],
  options: Options = defaultOptions
): Plot.Markish[] {
  options = { ...defaultOptions, ...options };
  const marks = [];

  marks.push(
    Plot.dot(data, {
      x: options.xKey,
      y: options.yKey,
      fill: 'category',
      opacity: options.showRegressionYPerCategory || options.showRegressionY ? 0.4 : 1,
      tip: 'x'
    })
  );

  if (options.showRegressionYPerCategory) {
    marks.push(
      Plot.linearRegressionY(data, { x: options.xKey, y: options.yKey, stroke: 'category' })
    );
  }
  if (options.showRegressionY) {
    marks.push(Plot.linearRegressionY(data, { x: options.xKey, y: options.yKey }));
  }

  return marks;
}
