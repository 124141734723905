import * as Plot from '@observablehq/plot';
import type Certainty from './certainty';

interface Options {
  cumulative?: boolean;
  normalize?: boolean;
  normalizeBasis?: Plot.NormalizeBasisName;
  showLabels?: boolean;
  xKey?: string;
  yKey?: string;
  windowK?: number;
  curve?: Plot.CurveName;
}

const defaultOptions = {
  cumulative: false,
  normalize: false,
  normalizeBasis: 'median' as Plot.NormalizeBasisName,
  showLabels: false,
  xKey: 'timestamp',
  yKey: 'value'
};

export default function generateLines(data: Certainty[], options: Options): Plot.Markish[] {
  options = { ...defaultOptions, ...options };
  const marks = [];

  const _lineOptions = {
    stroke: 'category',
    strokeWidth: 2,
    x: options.xKey,
    y: options.yKey,
    z: 'category',
    curve: options.curve?.length ? options.curve : undefined
  };

  if (options.cumulative) {
    const cumulativeOptions = {
      ..._lineOptions,
      tip: true
    };
    if (options.normalize) {
      marks.push(
        ...[
          Plot.lineY(
            data,
            Plot.normalizeY(
              options.normalizeBasis,
              Plot.mapY('cumsum', {
                filter: (d) => !d.predicted,
                ...cumulativeOptions
              } as Plot.LineYOptions)
            )
          ),
          Plot.lineY(
            data,
            Plot.normalizeY(
              options.normalizeBasis,
              Plot.mapY('cumsum', {
                ...cumulativeOptions,
                filter: (d) => d.predicted,
                strokeDasharray: '10,6'
              } as Plot.LineYOptions)
            )
          )
        ]
      );
    } else {
      marks.push(
        ...[
          Plot.lineY(
            data,
            Plot.mapY('cumsum', {
              filter: (d) => !d.predicted,
              ...cumulativeOptions
            })
          ),
          Plot.lineY(
            data,
            Plot.mapY('cumsum', {
              filter: (d) => d.predicted,
              ...cumulativeOptions,
              strokeDasharray: '10,6'
            })
          )
        ]
      );
    }
  } else {
    if (options.normalize) {
      marks.push(
        ...[
          Plot.lineY(
            data,
            Plot.normalizeY(options.normalizeBasis, {
              filter: (d) => !d.predicted,
              ..._lineOptions
            })
          ),
          Plot.lineY(
            data,
            Plot.normalizeY(options.normalizeBasis, {
              filter: (d) => d.predicted,
              ..._lineOptions,
              strokeDasharray: '10,6'
            })
          )
        ]
      );
    } else {
      if (options.windowK) {
        marks.push(
          ...[
            Plot.lineY(
              data,
              Plot.windowY({
                filter: (d) => !d.predicted,
                ..._lineOptions,
                k: options.windowK
              })
            ),
            Plot.lineY(
              data,
              Plot.windowY({
                filter: (d) => d.predicted,
                ..._lineOptions,
                strokeDasharray: '10,6',
                k: options.windowK
              })
            )
          ]
        );
      } else {
        marks.push(
          ...[
            Plot.lineY(data, {
              filter: (d) => !d.predicted,
              ..._lineOptions
            }),
            Plot.lineY(data, {
              filter: (d) => d.predicted,
              ..._lineOptions,
              strokeDasharray: '10,6'
            })
          ]
        );
      }
    }
  }

  if (options.showLabels) {
    const labelOptions: Plot.TextOptions & Plot.MapOptions = {
      text: 'category',
      x: 'timestamp',
      y: 'value',
      dy: -10,
      fontSize: 12,
      textAnchor: 'end',
      z: 'category'
    };
    if (options.cumulative) {
      if (options.normalize) {
        marks.push(
          Plot.text(
            data,
            Plot.selectMaxX(
              Plot.normalizeY(options.normalizeBasis, Plot.mapY('cumsum', labelOptions))
            )
          )
        );
      } else {
        marks.push(Plot.text(data, Plot.selectMaxX(Plot.mapY('cumsum', labelOptions))));
      }
    } else {
      if (options.normalize) {
        marks.push(
          Plot.text(data, Plot.normalizeY(options.normalizeBasis, Plot.selectMaxX(labelOptions)))
        );
      } else {
        marks.push(Plot.text(data, Plot.selectMaxX(labelOptions)));
      }
    }
  }

  return marks;
}
