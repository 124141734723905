import { useAtom } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components';
import { repredictAtom } from '../../state/campaign';
import { campaignIdAtom, refetchAtom } from '../../state/campaign_meta';
import OmniSearch from './OmniSearch';

const Styled = styled.div`
  display: flex;
  justify-content: space-between;

  fieldset {
    border: none;
  }

  > fieldset,
  > form {
    flex: 1 0 0;
    padding: 0 0.3rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const CampaignSearch = () => {
  const [campaignId] = useAtom(campaignIdAtom);
  const [, repredict] = useAtom(repredictAtom);
  const [, refetch] = useAtom(refetchAtom);

  useEffect(() => {
    if (!campaignId.length) return;
    refetch(campaignId).then(repredict);
  }, [campaignId, refetch, repredict]);

  return (
    <Styled>
      <OmniSearch />
    </Styled>
  );
};

export default CampaignSearch;
