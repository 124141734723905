import { useAtom } from 'jotai';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { campaignLoadingAtom, campaignNameAtom } from './Campaign/state/campaign_meta';
import CampaignHistory from './Campaign/ui/CampaignCard';
import CampaignSearchGrid from './Campaign/ui/CampaignSearchGrid';
import BooleanExpressionEntrypoint from './Segments/ui/BooleanExpression/BooleanExpressionEntrypoint';
import DetailAllSwaps from './Segments/ui/DetailAllSwaps';
import AuthenticatedLayout from './Users/ui/AuthenticatedLayout';
import Card from './components/Card';
import Grid from './components/Layout/Grid';
import GridItem from './components/Layout/GridItem';
import Loading from './components/Loading/LoadingDots';
import './styles/App.scss';

function App() {
  const [campaignName] = useAtom(campaignNameAtom);
  const [loading] = useAtom(campaignLoadingAtom);

  if (!campaignName) {
    return (
      <AuthenticatedLayout>
        <CampaignSearchGrid />
        {loading && <Loading />}
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout>
      <CampaignSearchGrid />
      <Grid>
        <GridItem $columns={2}>
          <Card
            type="text"
            title={
              <h3>
                Segments {loading && <Loading />} {!loading && <DetailAllSwaps />}
              </h3>
            }>
            <div style={{ width: '100%' }}>
              <BooleanExpressionEntrypoint />
            </div>
          </Card>
        </GridItem>
        <GridItem $columns={2}>
          <CampaignHistory />
        </GridItem>
      </Grid>
    </AuthenticatedLayout>
  );
}

export default App;
