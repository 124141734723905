import type { ServiceType } from '@bufbuild/protobuf';
import type { Interceptor, PromiseClient } from '@connectrpc/connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';

const baseUrl = process.env.REACT_APP_BASE_URL ?? 'http://localhost:8080';
const authorizationInterceptor: Interceptor = (next) => async (req) => {
  const token = '123demo';
  if (token) {
    req.header.set('Authorization', `Bearer ${token}`);
  } else {
    // There's a loading delay from local storage; this refetches the token
    // and avoids putting errors downstream in requests like chat initialization.
    await new Promise((r) => setTimeout(r, 100));
    const refetchedToken = '123demo';
    req.header.set('Authorization', `Bearer ${refetchedToken}`);
  }
  return await next(req);
};

// This transport is going to be used throughout the app
const transport = createGrpcWebTransport({
  baseUrl,
  useBinaryFormat: true,
  interceptors: [authorizationInterceptor]
});

const memoizedClients = new Map<ServiceType, PromiseClient<ServiceType>>();

// https://connectrpc.com/docs/web/using-clients#managing-clients-and-transports
const withClient = <T extends ServiceType>(service: T): PromiseClient<T> => {
  const client = memoizedClients.get(service);
  if (client) return client as PromiseClient<T>;
  memoizedClients.set(service, createPromiseClient(service, transport)) as PromiseClient<T>;
  return memoizedClients.get(service) as PromiseClient<T>;
};
export default withClient;
