// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/boolean_expression.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message recommenderpb.BooleanExpression
 */
export class BooleanExpression extends Message<BooleanExpression> {
  /**
   * e.g. AND, OR, NOT, IDENTITY
   *
   * @generated from field: string op = 1;
   */
  op = "";

  /**
   * @generated from field: repeated string ids = 2;
   */
  ids: string[] = [];

  /**
   * @generated from field: repeated recommenderpb.BooleanExpression children = 3;
   */
  children: BooleanExpression[] = [];

  constructor(data?: PartialMessage<BooleanExpression>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.BooleanExpression";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "op", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "children", kind: "message", T: BooleanExpression, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BooleanExpression {
    return new BooleanExpression().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BooleanExpression {
    return new BooleanExpression().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BooleanExpression {
    return new BooleanExpression().fromJsonString(jsonString, options);
  }

  static equals(a: BooleanExpression | PlainMessage<BooleanExpression> | undefined, b: BooleanExpression | PlainMessage<BooleanExpression> | undefined): boolean {
    return proto3.util.equals(BooleanExpression, a, b);
  }
}

