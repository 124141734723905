// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/predict.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BooleanExpressionRequest, CampaignPrediction, CampaignRequest } from "./predict_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service recommenderpb.Predict
 */
export const Predict = {
  typeName: "recommenderpb.Predict",
  methods: {
    /**
     * @generated from rpc recommenderpb.Predict.ByCampaign
     */
    byCampaign: {
      name: "ByCampaign",
      I: CampaignRequest,
      O: CampaignPrediction,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc recommenderpb.Predict.ByBooleanExpression
     */
    byBooleanExpression: {
      name: "ByBooleanExpression",
      I: BooleanExpressionRequest,
      O: CampaignPrediction,
      kind: MethodKind.Unary,
    },
  }
} as const;

