import { FC } from 'react';
import styled from 'styled-components';

interface IconProps {
  name?: string;
}

const Svg = styled.svg`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  outline: none;
  vertical-align: middle;
`;

const Icon: FC<IconProps> = ({ name = 'edit' }) => {
  return (
    <Svg onKeyDown={() => null} role="button" tabIndex={-1}>
      <use href={`/icons.svg#icon-${name}`} />
    </Svg>
  );
};

export default Icon;
