// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file graph/line_chart.proto (package graph, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message graph.LineChart
 */
export class LineChart extends Message<LineChart> {
  /**
   * @generated from field: bool show_line_labels = 1;
   */
  showLineLabels = false;

  /**
   * Must correspond to Plot.CurveName
   * https://observablehq.com/plot/features/curves
   *
   * @generated from field: string curve = 2;
   */
  curve = "";

  /**
   * Uses a rolling window to transform the feed.
   * Incompatible with normalize.
   * https://observablehq.com/plot/transforms/window
   *
   * @generated from field: int32 window_k = 3;
   */
  windowK = 0;

  constructor(data?: PartialMessage<LineChart>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "graph.LineChart";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_line_labels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "curve", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "window_k", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LineChart {
    return new LineChart().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LineChart {
    return new LineChart().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LineChart {
    return new LineChart().fromJsonString(jsonString, options);
  }

  static equals(a: LineChart | PlainMessage<LineChart> | undefined, b: LineChart | PlainMessage<LineChart> | undefined): boolean {
    return proto3.util.equals(LineChart, a, b);
  }
}

