import Tracker from '@openreplay/tracker';

export const tracker = new Tracker({
  projectKey: 'oPgTUsooBTCfmBVyWn5a',
  ingestPoint: 'https://openreplay.mad.dev/ingest',
  obscureInputNumbers: false,
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureTextNumbers: false,
  defaultInputMode: 0
});
