// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file graph/scatter_plot.proto (package graph, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message graph.ScatterPlot
 */
export class ScatterPlot extends Message<ScatterPlot> {
  /**
   * @generated from field: bool show_linear_regression_y_per_category = 1;
   */
  showLinearRegressionYPerCategory = false;

  /**
   * @generated from field: bool show_linear_regression_y = 2;
   */
  showLinearRegressionY = false;

  constructor(data?: PartialMessage<ScatterPlot>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "graph.ScatterPlot";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_linear_regression_y_per_category", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "show_linear_regression_y", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScatterPlot {
    return new ScatterPlot().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScatterPlot {
    return new ScatterPlot().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScatterPlot {
    return new ScatterPlot().fromJsonString(jsonString, options);
  }

  static equals(a: ScatterPlot | PlainMessage<ScatterPlot> | undefined, b: ScatterPlot | PlainMessage<ScatterPlot> | undefined): boolean {
    return proto3.util.equals(ScatterPlot, a, b);
  }
}

