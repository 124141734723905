import React from 'react';
import styled from 'styled-components';
import FeedbackButton from './FeedbackButton';
import HighContrastToggle from './HighContrastToggle';

const Styled = styled.div`
  text-align: right;

  button {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 1rem auto 0;
    cursor: pointer;
  }
`;

const Settings: React.FC = () => {
  return (
    <Styled>
      <HighContrastToggle />
      <FeedbackButton />
    </Styled>
  );
};

export default Settings;
