import * as d3 from 'd3';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { repredictAtom } from '../state/campaign';
import { existingBookedImpressionsAtom, wipBookedImpressionsAtom } from '../state/campaign_meta';

const Container = styled.div`
  margin: 2rem 0;
`;

const Styled = styled.form`
  display: flex;
  margin: 0.5rem 0 0;
  justify-content: space-between;

  input {
    border: 0;
    border-bottom: 1px solid var(--color-border);
    border-radius: 4px;
    font-size: 0.9rem;
    flex: 1 0 0;
    max-width: 100px;
    padding: 0.2rem;
    width: 100%;
  }

  .add-button {
    background: rgba(var(--color-emphasis-rgb), 0.6);
    box-shadow: 0 0 0;
    cursor: pointer;
    color: var(--color-white);
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 0.9;
    padding: 0.6rem 0.8rem;
    transition:
      0.2s box-shadow,
      0.2s background;

    &:nth-child(3) {
      background: rgba(var(--color-emphasis-rgb), 0.8);
    }

    &:nth-child(4) {
      background: rgba(var(--color-emphasis-rgb), 1);
    }

    &:hover {
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      background: rgba(var(--color-emphasis-rgb), 1);
    }

    &.-reset {
      background: rgba(var(--color-emphasis-rgb), 0.2);
      color: var(--color-impressions);

      &:hover {
        background: rgba(var(--color-emphasis-rgb), 0.3);
      }
    }
  }
`;

const CampaignBookedImpressions = () => {
  const [localImpressions, setLocalImpressions] = useState(0);
  const [, setBookedImpressions] = useAtom(wipBookedImpressionsAtom);
  const [existingBookedImpressions] = useAtom(existingBookedImpressionsAtom);
  const [impressionsIncrement, setImpressionScale] = useState(0);
  const [, repredict] = useAtom(repredictAtom);

  useEffect(() => {
    setLocalImpressions(existingBookedImpressions);
    const scale = Math.pow(10, Math.floor(Math.log10(existingBookedImpressions)) - 1);
    setImpressionScale(scale);
  }, [existingBookedImpressions, setImpressionScale, setLocalImpressions]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalImpressions(parseInt(event.target.value, 10));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setBookedImpressions(localImpressions);
    repredict();
  };

  const handleIncrement = (increment: number) => {
    setBookedImpressions(localImpressions + increment);
    setLocalImpressions(localImpressions + increment);
    repredict();
  };

  if (existingBookedImpressions === 0) return null;

  return (
    <Container>
      <h5>Booked Impressions</h5>
      <Styled onSubmit={handleSubmit}>
        <input type="number" value={localImpressions} onChange={handleChange} placeholder="1000" />
        <div className="add-button" onClick={() => handleIncrement(impressionsIncrement)}>
          Add {d3.format('.0s')(impressionsIncrement)} Impressions
        </div>
        <div className="add-button" onClick={() => handleIncrement(impressionsIncrement * 10)}>
          Add {d3.format('.0s')(impressionsIncrement * 10)} Impressions
        </div>
        <div className="add-button" onClick={() => handleIncrement(impressionsIncrement * 100)}>
          Add {d3.format('.0s')(impressionsIncrement * 100)} Impressions
        </div>
        <div
          className="add-button -reset"
          onClick={() => handleIncrement(existingBookedImpressions - localImpressions)}>
          Reset
        </div>
      </Styled>
    </Container>
  );
};

export default CampaignBookedImpressions;
