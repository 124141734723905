import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import { Graph, Graph_FeedTransformer } from '../../../protos/graph/graph_pb';
import Certainty from './certainty';
import generateAreas from './generate_areas';
import generateBars from './generate_bars';
import generateHistogram from './generate_histogram';
import generateLines from './generate_lines';
import generateScatterPlot from './generate_scatter_plot';

interface withDate {
  timestamp: Date | undefined;
  predicted: boolean | undefined;
  value: number;
  category: string;
}

function buildTimeSeries(feed: Graph_FeedTransformer, data: withDate[], config: Graph) {
  switch (feed.renderAs.case) {
    case 'area':
      return generateAreas(data, {
        cumulative: feed.cumulativeSum,
        showLabels: feed.renderAs.value.showLineLabels,
        xKey: 'timestamp'
      });
    case 'line':
      return generateLines(data, {
        cumulative: feed.cumulativeSum,
        normalize: config.shouldNormalizeFeeds,
        showLabels: feed.renderAs.value.showLineLabels,
        xKey: 'timestamp',
        windowK: feed.renderAs.value.windowK,
        curve: feed.renderAs.value.curve as Plot.CurveName | undefined
      });
    case 'horizontalBar':
    case 'verticalBar':
      return generateBars(data, {
        horizontal: feed.renderAs.case === 'horizontalBar',
        xKey: feed.renderAs.case === 'horizontalBar' ? 'timestamp' : feed.renderAs.value.yKey,
        yKey: feed.renderAs.case === 'horizontalBar' ? feed.renderAs.value.yKey : 'timestamp',
        y2Key: feed.renderAs.value.y2Key,
        groupBy: feed.renderAs.value.groupBy,
        tip: feed.renderAs.value.tip
      });
    case 'scatterPlot':
      return generateScatterPlot(data, {
        showRegressionY: feed.renderAs.value.showLinearRegressionY,
        showRegressionYPerCategory: feed.renderAs.value.showLinearRegressionYPerCategory,
        xKey: 'timestamp'
      });
  }
  return [];
}

function generateCrossHairs(data: any[], xKey: string, yKey: string): Plot.Markish[] {
  return [
    Plot.ruleX(
      data,
      Plot.pointerX({
        x: xKey,
        opacity: 0.2,
        insetBottom: 5
      })
    ),
    Plot.text(
      data,
      Plot.pointerX({
        x: xKey,
        dy: 5,
        frameAnchor: 'bottom',
        text:
          xKey === 'timestamp'
            ? (d) => d3.timeFormat('%b %d %H:%M')(d[xKey])
            : (d) => d3.format('.3s')(d[xKey])
      })
    ),
    Plot.ruleY(
      data,
      Plot.pointerY({
        y: yKey,
        opacity: 0.2,
        insetLeft: 25
      })
    ),
    Plot.text(
      data,
      Plot.pointerY({
        y: yKey,
        frameAnchor: 'left',
        dx: -5,
        text: (d) => d3.format('.3s')(d[yKey])
      })
    )
  ];
}

function buildNumericSeries(feed: Graph_FeedTransformer, data: Certainty[], config: Graph) {
  switch (feed.renderAs.case) {
    case 'area':
      return generateAreas(data, {
        cumulative: feed.cumulativeSum,
        showLabels: feed.renderAs.value.showLineLabels,
        xKey: 'value',
        yKey: 'value2',
        y1Key: 'value3',
        y2Key: 'value4'
      });
    case 'histogram':
    case 'histogramLine':
      return generateHistogram(data, {
        asLines: feed.renderAs.case === 'histogramLine',
        cumulative: feed.cumulativeSum,
        facetBy: config.facetBy,
        normalize: config.shouldNormalizeFeeds,
        showLineLabels:
          feed.renderAs.case === 'histogramLine' ? feed.renderAs.value.showLineLabels : false
      });
    case 'horizontalBar':
    case 'verticalBar':
      return generateBars(data, {
        horizontal: feed.renderAs.case === 'horizontalBar',
        xKey: feed.renderAs.value.xKey,
        yKey: feed.renderAs.value.yKey,
        y2Key: feed.renderAs.value.y2Key,
        groupBy: feed.renderAs.value.groupBy,
        tip: feed.renderAs.value.tip
      });
    case 'line':
      return generateLines(data, {
        cumulative: feed.cumulativeSum,
        normalize: config.shouldNormalizeFeeds,
        showLabels: feed.renderAs.value.showLineLabels,
        windowK: feed.renderAs.value.windowK,
        curve: feed.renderAs.value.curve as Plot.CurveName | undefined,
        xKey: 'value',
        yKey: 'value2'
      });
    case 'scatterPlot':
      return generateScatterPlot(data, {
        showRegressionY: feed.renderAs.value.showLinearRegressionY,
        showRegressionYPerCategory: feed.renderAs.value.showLinearRegressionYPerCategory
      });
  }
  return [];
}

export default function protoToMarks(config: Graph): Plot.Markish[] {
  const dateData: any[] = [];
  const numericData: any[] = [];
  const marks = config?.feeds.reduce((acc, feed) => {
    switch (feed.feed?.feed?.case) {
      case 'timeSeries':
        let dataWithDate = feed.feed?.feed.value.data.map((d) => ({
          ...d,
          timestamp: d.timestamp?.toDate(),
          predicted: feed.feed?.predicted
        }));
        dataWithDate.sort((a, b) => (a.timestamp?.getTime() ?? 0) - (b.timestamp?.getTime() ?? 0));
        dateData.push(dataWithDate);
        return [...acc, ...buildTimeSeries(feed, dataWithDate, config)];

      case 'numeric':
        let dataWithAi: Certainty[] = feed.feed?.feed.value.data.map((d) => ({
          ...d,
          predicted: feed.feed?.predicted
        }));
        numericData.push(dataWithAi);
        return [...acc, ...buildNumericSeries(feed, dataWithAi, config)];
    }

    return acc;
  }, [] as Plot.Markish[]);

  if (
    config?.feeds.every((feed) => feed.renderAs.case === 'line' || feed.renderAs.case === 'area') ??
    false
  ) {
    if (dateData.length) marks.push(...generateCrossHairs(dateData.flat(), 'timestamp', 'value'));
    if (numericData.length)
      marks.push(...generateCrossHairs(numericData.flat(), 'value', 'value2'));
  }
  return marks;
}
