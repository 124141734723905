import * as Plot from '@observablehq/plot';
import type Certainty from './certainty';

interface Options {
  cumulative?: boolean;
  showLabels?: boolean;
  xKey?: string;
  yKey?: string;
  y1Key?: string;
  y2Key?: string;
}

const defaultOptions = {
  cumulative: false,
  showLabels: false,
  xKey: 'timestamp',
  yKey: 'value'
};

export default function generateAreas(data: Certainty[], options: Options): Plot.Markish[] {
  options = { ...defaultOptions, ...options };
  const marks = [];

  const _lineOptions = {
    stroke: 'category',
    strokeWidth: 2,
    x: options.xKey,
    y: options.yKey,
    z: 'category'
  };

  const areaYOptions: Plot.AreaYOptions = {
    x: options.xKey,
    y: options.yKey,
    fillOpacity: 0.08,
    fill: 'category',
    z: 'category'
  };
  if (options.y1Key && data.length && !!(data[0] as any)[options.y1Key]) {
    delete areaYOptions.y;
    areaYOptions.y1 = options.y1Key;
  }
  if (options.y2Key && data.length && !!(data[0] as any)[options.y2Key])
    areaYOptions.y2 = options.y2Key;

  if (options.cumulative) {
    marks.push(
      Plot.lineY(
        data,
        Plot.mapY('cumsum', {
          filter: (d) => !d.predicted,
          ..._lineOptions
        })
      )
    );
    marks.push(
      Plot.lineY(
        data,
        Plot.mapY('cumsum', {
          filter: (d) => d.predicted,
          strokeDasharray: '10,6',
          ..._lineOptions
        })
      )
    );
    marks.push(
      Plot.areaY(
        data,
        Plot.mapY('cumsum', {
          curve: 'monotone-x',
          title: 'category',
          ...areaYOptions
        })
      )
    );
  } else {
    marks.push(
      Plot.lineY(data, {
        filter: (d) => !d.predicted,
        ..._lineOptions
      })
    );
    marks.push(
      Plot.lineY(data, {
        filter: (d) => d.predicted,
        strokeDasharray: '10,6',
        ..._lineOptions
      })
    );
    marks.push(Plot.areaY(data, areaYOptions));
  }

  if (options.showLabels) {
    if (options.cumulative) {
      marks.push(
        Plot.text(
          data,
          Plot.selectMaxX(
            Plot.mapY('cumsum', {
              text: 'category',
              x: options.xKey,
              y: options.yKey,
              dy: -6,
              textAnchor: 'end',
              z: 'category'
            })
          )
        )
      );
    } else {
      marks.push(
        Plot.text(
          data,
          Plot.selectMaxX({
            text: 'category',
            x: options.xKey,
            y: options.yKey,
            dy: -6,
            fontSize: 12,
            textAnchor: 'end',
            z: 'category'
          })
        )
      );
    }
  }

  return marks;
}
