// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/feedback.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message recommenderpb.FeedbackRequest
 */
export class FeedbackRequest extends Message<FeedbackRequest> {
  /**
   * @generated from field: bytes image = 1;
   */
  image = new Uint8Array(0);

  /**
   * @generated from field: string notes = 2;
   */
  notes = "";

  /**
   * @generated from field: string inst_id = 3;
   */
  instId = "";

  /**
   * @generated from field: string user_id = 4;
   */
  userId = "";

  /**
   * @generated from field: string client_id = 5;
   */
  clientId = "";

  constructor(data?: PartialMessage<FeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.FeedbackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "inst_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedbackRequest {
    return new FeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedbackRequest {
    return new FeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedbackRequest {
    return new FeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FeedbackRequest | PlainMessage<FeedbackRequest> | undefined, b: FeedbackRequest | PlainMessage<FeedbackRequest> | undefined): boolean {
    return proto3.util.equals(FeedbackRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.FeedbackResponse
 */
export class FeedbackResponse extends Message<FeedbackResponse> {
  /**
   * @generated from field: string error = 1;
   */
  error = "";

  constructor(data?: PartialMessage<FeedbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.FeedbackResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedbackResponse {
    return new FeedbackResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedbackResponse {
    return new FeedbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedbackResponse {
    return new FeedbackResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FeedbackResponse | PlainMessage<FeedbackResponse> | undefined, b: FeedbackResponse | PlainMessage<FeedbackResponse> | undefined): boolean {
    return proto3.util.equals(FeedbackResponse, a, b);
  }
}

