import React, { ReactNode } from 'react';
import './Card.scss';

interface CardProps {
  name?: string;
  type?: string;
  title?: ReactNode;
  children: ReactNode;
  showOverflow?: number;
}

const Card: React.FC<CardProps> = ({ name, type, children, title, showOverflow = 0 }) => {
  return (
    <div
      role="gridcell"
      tabIndex={-1}
      className={`card -type-${type} ${showOverflow ? '-overflow-visible' : ''}`}>
      <div className="title">
        {title && title}
        {!title && (name?.length ?? 0) > 0 && <h3>{name}</h3>}
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;
