import { PartialMessage } from '@bufbuild/protobuf';
import html2canvas from 'html2canvas';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { campaignIdAtom } from '../../Campaign/state/campaign_meta';
import { userEmailAtom } from '../../Users/state/authenticated';
import FeedbackModal from '../../components/Layout/Modal';
import withClient from '../../proto/with_client';
import { Feedback } from '../../protos/recommender/feedback_connect';
import { FeedbackRequest } from '../../protos/recommender/feedback_pb';

const FeedbackContent = styled.div`
  display: flex;
  justify-content: space-between;

  canvas {
    max-width: 100%;
    height: auto !important;
  }

  form {
    flex: 1 0 0;
  }

  > div {
    padding: 0 0.5rem;
    flex: 3 0 0;
  }

  textarea {
    min-height: 100px;
  }

  button {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 2px solid var(--color-emphasis);
    text-transform: uppercase;
    color: var(--color-emphasis);
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem auto 0;
    border-radius: 1rem;
    text-align: center;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: var(--color-emphasis);
      color: var(--color-white);
    }
  }
`;

const FeedbackButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const [dataUrl, setDataUrl] = useState<string>('');
  const [instId] = useAtom(campaignIdAtom);
  const [userEmail] = useAtom(userEmailAtom);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Submit');

  const handleClose = () => setIsOpen(false);

  function prepareUpload(): PartialMessage<FeedbackRequest> {
    // remove "data:image/png;base64," from the start of the dataURL
    const base64String = dataUrl?.split(',')[1];

    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return {
      image: bytes,
      userId: userEmail,
      instId,
      notes
    };
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNotes(event.target.value);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit();
    }
  };
  const client = withClient(Feedback);
  const submit = async () => {
    setLoading(true);
    setMessage('Uploading...');
    try {
      await client.upload(prepareUpload());
      setNotes('');
      setMessage('Uploaded');
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    } catch (error) {
      setMessage('Failed to upload');
      setTimeout(() => {
        setMessage('Submit');
      }, 2000);
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!shouldOpen) {
      return setIsOpen(false);
    }

    html2canvas(document.body, { logging: false }).then((canvas) => {
      setIsOpen(true);

      // Wait for ref to be populated
      setTimeout(() => {
        while (ref.current?.firstChild) {
          ref.current.firstChild.remove();
        }

        ref.current?.appendChild(canvas);
      }, 1);

      setDataUrl(canvas.toDataURL());
    });
  }, [shouldOpen, setDataUrl, setIsOpen]);

  return (
    <>
      <button onClick={() => setShouldOpen(!shouldOpen)}>Send Feedback</button>
      <FeedbackModal isOpen={isOpen} onClose={handleClose}>
        <h2>Send Feedback</h2>
        <p>Be honest, be specific, be bold</p>
        <FeedbackContent>
          <div ref={ref} />
          <form>
            <textarea
              autoFocus={true}
              value={notes}
              placeholder="Say more, if you want"
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
            <button type="submit" onClick={submit} disabled={loading}>
              {message}
            </button>
          </form>
        </FeedbackContent>
      </FeedbackModal>
    </>
  );
};

export default FeedbackButton;
