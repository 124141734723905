import { atom } from 'jotai';
import { campaignIdAtom, existingExprAtom, wipExprAtom } from '../../Campaign/state/campaign_meta';
import withClient from '../../proto/with_client';
import { Meta } from '../../protos/recommender/meta_connect';
import { Segment } from '../../protos/recommender/segment_pb';

export const segmentByIdAtom = atom<Record<string, Segment>>({});
export const existingSegmentByIdAtom = atom<Record<string, boolean>>({});
export const wipReachByIdAtom = atom<Record<string, number>>({});

// eslint-disable-next-line react-hooks/rules-of-hooks
const client = withClient(Meta);

export const refetchAtom = atom(null, async (get, set) => {
  const existingExpr = get(existingExprAtom);
  const wipExpr = get(wipExprAtom);
  const campaignId = get(campaignIdAtom);

  try {
    const resp = await client.segments({ campaignId, existingExpr, wipExpr });

    const segments = get(wipReachByIdAtom);
    for (const segment of resp.segments) {
      segments[segment.id] = segment.reach;
    }
    set(wipReachByIdAtom, segments);
  } catch (e) {
    console.warn(e);
  }
});
