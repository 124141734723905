import { ReactNode } from 'react';
import styled from 'styled-components';

interface ContentProps {
  children?: ReactNode;
}

const Styled = styled.div`
  padding: 0 var(--spacing-window-horizontal);
  max-width: 1440px;
  margin: 0 auto;
`;

const Content: React.FC<ContentProps> = ({ children }) => {
  return <Styled>{children}</Styled>;
};

export default Content;
