// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file graph/graph.proto (package graph, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Feed } from "../feed/feed_pb";
import { LineChart } from "./line_chart_pb";
import { BarChart } from "./bar_chart_pb";
import { ScatterPlot } from "./scatter_plot_pb";

/**
 * Many feeds into one widget with at least one chart
 *
 * @generated from message graph.Graph
 */
export class Graph extends Message<Graph> {
  /**
   * @generated from field: repeated graph.Graph.FeedTransformer feeds = 1;
   */
  feeds: Graph_FeedTransformer[] = [];

  /**
   * @generated from field: graph.Graph.Meta meta = 2;
   */
  meta?: Graph_Meta;

  /**
   * The property of the data to use to
   * create multiple charts
   *
   * All data points must have a property that matches this value
   * e.g. "category"
   *
   * @generated from field: string facet_by = 3;
   */
  facetBy = "";

  /**
   * Divide all values by the median
   * https://observablehq.com/@observablehq/plot-cheatsheets-transforms#-normalize-https-observablehq-com-observablehq-plot-map-collection-observablehq-plot-
   *
   * @generated from field: bool should_normalize_feeds = 4;
   */
  shouldNormalizeFeeds = false;

  constructor(data?: PartialMessage<Graph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "graph.Graph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "feeds", kind: "message", T: Graph_FeedTransformer, repeated: true },
    { no: 2, name: "meta", kind: "message", T: Graph_Meta },
    { no: 3, name: "facet_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "should_normalize_feeds", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Graph {
    return new Graph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Graph {
    return new Graph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Graph {
    return new Graph().fromJsonString(jsonString, options);
  }

  static equals(a: Graph | PlainMessage<Graph> | undefined, b: Graph | PlainMessage<Graph> | undefined): boolean {
    return proto3.util.equals(Graph, a, b);
  }
}

/**
 * @generated from message graph.Graph.Meta
 */
export class Graph_Meta extends Message<Graph_Meta> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string x_axis_label = 2;
   */
  xAxisLabel = "";

  /**
   * @generated from field: string y_axis_label = 3;
   */
  yAxisLabel = "";

  /**
   * @generated from field: bool show_x_axis_lines = 4;
   */
  showXAxisLines = false;

  /**
   * @generated from field: bool show_y_axis_lines = 5;
   */
  showYAxisLines = false;

  /**
   * @generated from field: bool show_legend = 6;
   */
  showLegend = false;

  /**
   * @generated from field: bool uses_time_scale = 7;
   */
  usesTimeScale = false;

  /**
   * @generated from field: string x_tick_format = 8;
   */
  xTickFormat = "";

  /**
   * @generated from field: bool hide_x_axis = 9;
   */
  hideXAxis = false;

  constructor(data?: PartialMessage<Graph_Meta>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "graph.Graph.Meta";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "x_axis_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "y_axis_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "show_x_axis_lines", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "show_y_axis_lines", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "show_legend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "uses_time_scale", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "x_tick_format", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "hide_x_axis", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Graph_Meta {
    return new Graph_Meta().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Graph_Meta {
    return new Graph_Meta().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Graph_Meta {
    return new Graph_Meta().fromJsonString(jsonString, options);
  }

  static equals(a: Graph_Meta | PlainMessage<Graph_Meta> | undefined, b: Graph_Meta | PlainMessage<Graph_Meta> | undefined): boolean {
    return proto3.util.equals(Graph_Meta, a, b);
  }
}

/**
 * @generated from message graph.Graph.FeedTransformer
 */
export class Graph_FeedTransformer extends Message<Graph_FeedTransformer> {
  /**
   * @generated from field: feed.Feed feed = 1;
   */
  feed?: Feed;

  /**
   * @generated from field: bool cumulative_sum = 2;
   */
  cumulativeSum = false;

  /**
   * @generated from field: string category_match = 3;
   */
  categoryMatch = "";

  /**
   * @generated from oneof graph.Graph.FeedTransformer.render_as
   */
  renderAs: {
    /**
     * @generated from field: graph.LineChart line = 4;
     */
    value: LineChart;
    case: "line";
  } | {
    /**
     * @generated from field: graph.LineChart area = 5;
     */
    value: LineChart;
    case: "area";
  } | {
    /**
     * @generated from field: graph.BarChart horizontal_bar = 6;
     */
    value: BarChart;
    case: "horizontalBar";
  } | {
    /**
     * @generated from field: graph.BarChart histogram = 7;
     */
    value: BarChart;
    case: "histogram";
  } | {
    /**
     * @generated from field: graph.LineChart histogram_line = 8;
     */
    value: LineChart;
    case: "histogramLine";
  } | {
    /**
     * @generated from field: graph.BarChart vertical_bar = 9;
     */
    value: BarChart;
    case: "verticalBar";
  } | {
    /**
     * @generated from field: graph.ScatterPlot scatter_plot = 10;
     */
    value: ScatterPlot;
    case: "scatterPlot";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Graph_FeedTransformer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "graph.Graph.FeedTransformer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "feed", kind: "message", T: Feed },
    { no: 2, name: "cumulative_sum", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "category_match", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "line", kind: "message", T: LineChart, oneof: "render_as" },
    { no: 5, name: "area", kind: "message", T: LineChart, oneof: "render_as" },
    { no: 6, name: "horizontal_bar", kind: "message", T: BarChart, oneof: "render_as" },
    { no: 7, name: "histogram", kind: "message", T: BarChart, oneof: "render_as" },
    { no: 8, name: "histogram_line", kind: "message", T: LineChart, oneof: "render_as" },
    { no: 9, name: "vertical_bar", kind: "message", T: BarChart, oneof: "render_as" },
    { no: 10, name: "scatter_plot", kind: "message", T: ScatterPlot, oneof: "render_as" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Graph_FeedTransformer {
    return new Graph_FeedTransformer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Graph_FeedTransformer {
    return new Graph_FeedTransformer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Graph_FeedTransformer {
    return new Graph_FeedTransformer().fromJsonString(jsonString, options);
  }

  static equals(a: Graph_FeedTransformer | PlainMessage<Graph_FeedTransformer> | undefined, b: Graph_FeedTransformer | PlainMessage<Graph_FeedTransformer> | undefined): boolean {
    return proto3.util.equals(Graph_FeedTransformer, a, b);
  }
}

