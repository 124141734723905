import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Settings from '../../Settings/ui/Settings';
import Content from '../../components/Layout/Content';
import { userEmailAtom } from '../state/authenticated';
import firebaseConfig from '../state/firebase_config';
import { tracker } from '../state/openreplay';

interface LayoutProps {
  children: React.ReactNode;
}

const provider = new GoogleAuthProvider();
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;

  .login-button {
    border-radius: 8px;
    background: var(--color-white);
    box-shadow: 0 2px 1px 0 #ccc;
    color: var(--color-body);
    padding: 9px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s linear;

    &:hover {
      background: #f2f2f2;
    }

    svg {
      display: inline-block;
      margin-right: 5px;
    }
  }
`;

const AuthenticatedLayout: React.FC<LayoutProps> = ({ children }) => {
  const [unauthenticated, setUnauthenticated] = useState(true);
  const [, setUserEmail] = useAtom(userEmailAtom);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUserEmail(firebaseUser?.email || '');
      tracker.setUserID(firebaseUser?.email || '');
      setUnauthenticated(!firebaseUser);
    });
    return () => unsubscribe();
  }, [setUserEmail]);

  const handleLogin = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    signInWithPopup(auth, provider);
  };

  if (!unauthenticated) {
    return (
      <main>
        <Content>
          <Settings />
          {children}
        </Content>
      </main>
    );
  }

  return (
    <main>
      <Content>
        <Wrapper>
          <a href="#login" className="login-button" onClick={handleLogin}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
              <path d="M1 1h22v22H1z" fill="none" />
            </svg>
            Sign in with Google
          </a>
        </Wrapper>
      </Content>
    </main>
  );
};

export default AuthenticatedLayout;
