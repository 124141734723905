import { useAtom } from 'jotai';
import React from 'react';
import styled from 'styled-components';
import Card from '../../components/Card';
import Loading from '../../components/Loading/LoadingDots';
import { graphsAtom, loadingAtom } from '../state/campaign';
import { campaignNameAtom } from '../state/campaign_meta';
import CampaignBookedImpressions from './CampaignBookedImpressions';
import CampaignCurrentTime from './CampaignCurrentTime';
import CampaignGraphs from './CampaignGraphs';
import CampaignPredictUsingDataBefore from './CampaignPredictUsingDataBefore';
import CampaignStatistics from './CampaignStatistics';

const CampaignHistoryComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
`;

const CampaignGraphsSection: React.FC = () => {
  const [predictions] = useAtom(graphsAtom);

  if (!predictions?.length) {
    return (
      <CampaignHistoryComponent>
        <Loading />
      </CampaignHistoryComponent>
    );
  }

  return <CampaignGraphs graphs={predictions} />;
};

const CampaignCard: React.FC = () => {
  const [name] = useAtom(campaignNameAtom);
  const [loading] = useAtom(loadingAtom);

  return (
    <Card
      name={name ?? 'Inst'}
      type="poly"
      title={
        <h3>
          <span>{name}</span> {loading && <Loading />}
        </h3>
      }>
      <CampaignGraphsSection />
      <CampaignStatistics />
      <CampaignBookedImpressions />
      <CampaignCurrentTime />
      <CampaignPredictUsingDataBefore />
    </Card>
  );
};

export default CampaignCard;
