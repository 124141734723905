import React from 'react';
import styled from 'styled-components';

interface Props {
  $forSuggestions?: number;
  $visible?: number;
}
interface ComponentProps extends Props {
  $text: string;
}

const Styled = styled.h5<Props>`
  color: var(--color-recorded);
  display: block;
  font-size: ${(props) => (props.$forSuggestions ? '0.6rem' : '0.8rem')};
  font-weight: bold;
  line-height: 1.4;
  margin: 0;
  padding: 0 0.3rem;
  text-transform: uppercase;

  + h5 {
    display: none;
  }
`;

const BooleanExpressionOp: React.FC<ComponentProps> = ({
  $text,
  $forSuggestions,
  $visible = 1
}) => (
  <Styled $forSuggestions={$forSuggestions} $visible={$visible}>
    {$forSuggestions ? ($text.toUpperCase() === 'IDENTITY' ? 'OR' : $text) : $text}
  </Styled>
);

export default BooleanExpressionOp;
