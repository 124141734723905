// @generated by protoc-gen-es v1.8.0 with parameter "target=ts,import_extension=none"
// @generated from file recommender/meta.proto (package recommenderpb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { BooleanExpression } from "./boolean_expression_pb";
import { Segment } from "./segment_pb";

/**
 * @generated from message recommenderpb.ReachByBooleanExpressionRequest
 */
export class ReachByBooleanExpressionRequest extends Message<ReachByBooleanExpressionRequest> {
  /**
   * @generated from field: recommenderpb.BooleanExpression expr = 1;
   */
  expr?: BooleanExpression;

  constructor(data?: PartialMessage<ReachByBooleanExpressionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.ReachByBooleanExpressionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "expr", kind: "message", T: BooleanExpression },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReachByBooleanExpressionRequest {
    return new ReachByBooleanExpressionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReachByBooleanExpressionRequest {
    return new ReachByBooleanExpressionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReachByBooleanExpressionRequest {
    return new ReachByBooleanExpressionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReachByBooleanExpressionRequest | PlainMessage<ReachByBooleanExpressionRequest> | undefined, b: ReachByBooleanExpressionRequest | PlainMessage<ReachByBooleanExpressionRequest> | undefined): boolean {
    return proto3.util.equals(ReachByBooleanExpressionRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.ReachByBooleanExpressionResponse
 */
export class ReachByBooleanExpressionResponse extends Message<ReachByBooleanExpressionResponse> {
  /**
   * @generated from field: int32 reach = 1;
   */
  reach = 0;

  constructor(data?: PartialMessage<ReachByBooleanExpressionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.ReachByBooleanExpressionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReachByBooleanExpressionResponse {
    return new ReachByBooleanExpressionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReachByBooleanExpressionResponse {
    return new ReachByBooleanExpressionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReachByBooleanExpressionResponse {
    return new ReachByBooleanExpressionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReachByBooleanExpressionResponse | PlainMessage<ReachByBooleanExpressionResponse> | undefined, b: ReachByBooleanExpressionResponse | PlainMessage<ReachByBooleanExpressionResponse> | undefined): boolean {
    return proto3.util.equals(ReachByBooleanExpressionResponse, a, b);
  }
}

/**
 * @generated from message recommenderpb.CampaignMetaRequest
 */
export class CampaignMetaRequest extends Message<CampaignMetaRequest> {
  /**
   * @generated from field: string campaign_id = 1;
   */
  campaignId = "";

  constructor(data?: PartialMessage<CampaignMetaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.CampaignMetaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignMetaRequest {
    return new CampaignMetaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignMetaRequest {
    return new CampaignMetaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignMetaRequest {
    return new CampaignMetaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignMetaRequest | PlainMessage<CampaignMetaRequest> | undefined, b: CampaignMetaRequest | PlainMessage<CampaignMetaRequest> | undefined): boolean {
    return proto3.util.equals(CampaignMetaRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.CampaignMetaResponse
 */
export class CampaignMetaResponse extends Message<CampaignMetaResponse> {
  /**
   * @generated from field: google.protobuf.Timestamp end = 1;
   */
  end?: Timestamp;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp start = 3;
   */
  start?: Timestamp;

  /**
   * @generated from field: repeated recommenderpb.Segment segments = 4;
   */
  segments: Segment[] = [];

  /**
   * @generated from field: int32 booked_impressions = 5;
   */
  bookedImpressions = 0;

  /**
   * @generated from field: recommenderpb.BooleanExpression expr = 6;
   */
  expr?: BooleanExpression;

  /**
   * @generated from field: double fulfillment_percentage = 7;
   */
  fulfillmentPercentage = 0;

  /**
   * @generated from field: int32 total_reach = 8;
   */
  totalReach = 0;

  /**
   * @generated from field: recommenderpb.Pricing pricing = 9;
   */
  pricing?: Pricing;

  constructor(data?: PartialMessage<CampaignMetaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.CampaignMetaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "end", kind: "message", T: Timestamp },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start", kind: "message", T: Timestamp },
    { no: 4, name: "segments", kind: "message", T: Segment, repeated: true },
    { no: 5, name: "booked_impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "expr", kind: "message", T: BooleanExpression },
    { no: 7, name: "fulfillment_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "total_reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "pricing", kind: "message", T: Pricing },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignMetaResponse {
    return new CampaignMetaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignMetaResponse {
    return new CampaignMetaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignMetaResponse {
    return new CampaignMetaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignMetaResponse | PlainMessage<CampaignMetaResponse> | undefined, b: CampaignMetaResponse | PlainMessage<CampaignMetaResponse> | undefined): boolean {
    return proto3.util.equals(CampaignMetaResponse, a, b);
  }
}

/**
 * @generated from message recommenderpb.OmniSearchRequest
 */
export class OmniSearchRequest extends Message<OmniSearchRequest> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  constructor(data?: PartialMessage<OmniSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.OmniSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OmniSearchRequest {
    return new OmniSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OmniSearchRequest {
    return new OmniSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OmniSearchRequest {
    return new OmniSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OmniSearchRequest | PlainMessage<OmniSearchRequest> | undefined, b: OmniSearchRequest | PlainMessage<OmniSearchRequest> | undefined): boolean {
    return proto3.util.equals(OmniSearchRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.OmniSearchResponse
 */
export class OmniSearchResponse extends Message<OmniSearchResponse> {
  /**
   * @generated from field: repeated recommenderpb.OmniSearchResult results = 1;
   */
  results: OmniSearchResult[] = [];

  constructor(data?: PartialMessage<OmniSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.OmniSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: OmniSearchResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OmniSearchResponse {
    return new OmniSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OmniSearchResponse {
    return new OmniSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OmniSearchResponse {
    return new OmniSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OmniSearchResponse | PlainMessage<OmniSearchResponse> | undefined, b: OmniSearchResponse | PlainMessage<OmniSearchResponse> | undefined): boolean {
    return proto3.util.equals(OmniSearchResponse, a, b);
  }
}

/**
 * @generated from message recommenderpb.OmniSearchResult
 */
export class OmniSearchResult extends Message<OmniSearchResult> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string parent_name = 3;
   */
  parentName = "";

  /**
   * @generated from field: google.protobuf.Timestamp start = 4;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 5;
   */
  end?: Timestamp;

  /**
   * @generated from field: int32 booked_impressions = 6;
   */
  bookedImpressions = 0;

  /**
   * @generated from field: string hive = 7;
   */
  hive = "";

  /**
   * @generated from field: string adv = 8;
   */
  adv = "";

  /**
   * @generated from field: string agency = 9;
   */
  agency = "";

  /**
   * @generated from field: string station_group = 10;
   */
  stationGroup = "";

  /**
   * @generated from field: string station = 11;
   */
  station = "";

  /**
   * @generated from field: string ssp = 12;
   */
  ssp = "";

  /**
   * @generated from field: string pub_group = 13;
   */
  pubGroup = "";

  /**
   * @generated from field: string manu = 14;
   */
  manu = "";

  /**
   * @generated from field: string dmp = 15;
   */
  dmp = "";

  /**
   * @generated from field: string dsp = 16;
   */
  dsp = "";

  /**
   * @generated from field: string unknown = 17;
   */
  unknown = "";

  constructor(data?: PartialMessage<OmniSearchResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.OmniSearchResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parent_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
    { no: 6, name: "booked_impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "hive", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "adv", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "agency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "station_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "station", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "ssp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "pub_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "manu", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "dmp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "dsp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "unknown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OmniSearchResult {
    return new OmniSearchResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OmniSearchResult {
    return new OmniSearchResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OmniSearchResult {
    return new OmniSearchResult().fromJsonString(jsonString, options);
  }

  static equals(a: OmniSearchResult | PlainMessage<OmniSearchResult> | undefined, b: OmniSearchResult | PlainMessage<OmniSearchResult> | undefined): boolean {
    return proto3.util.equals(OmniSearchResult, a, b);
  }
}

/**
 * @generated from message recommenderpb.SegmentsMetaRequest
 */
export class SegmentsMetaRequest extends Message<SegmentsMetaRequest> {
  /**
   * @generated from field: string campaign_id = 1;
   */
  campaignId = "";

  /**
   * @generated from field: recommenderpb.BooleanExpression existing_expr = 2;
   */
  existingExpr?: BooleanExpression;

  /**
   * @generated from field: recommenderpb.BooleanExpression wip_expr = 3;
   */
  wipExpr?: BooleanExpression;

  constructor(data?: PartialMessage<SegmentsMetaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SegmentsMetaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "existing_expr", kind: "message", T: BooleanExpression },
    { no: 3, name: "wip_expr", kind: "message", T: BooleanExpression },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SegmentsMetaRequest {
    return new SegmentsMetaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SegmentsMetaRequest {
    return new SegmentsMetaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SegmentsMetaRequest {
    return new SegmentsMetaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SegmentsMetaRequest | PlainMessage<SegmentsMetaRequest> | undefined, b: SegmentsMetaRequest | PlainMessage<SegmentsMetaRequest> | undefined): boolean {
    return proto3.util.equals(SegmentsMetaRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.SegmentsMetaResponse
 */
export class SegmentsMetaResponse extends Message<SegmentsMetaResponse> {
  /**
   * @generated from field: repeated recommenderpb.Segment segments = 1;
   */
  segments: Segment[] = [];

  constructor(data?: PartialMessage<SegmentsMetaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SegmentsMetaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segments", kind: "message", T: Segment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SegmentsMetaResponse {
    return new SegmentsMetaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SegmentsMetaResponse {
    return new SegmentsMetaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SegmentsMetaResponse {
    return new SegmentsMetaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SegmentsMetaResponse | PlainMessage<SegmentsMetaResponse> | undefined, b: SegmentsMetaResponse | PlainMessage<SegmentsMetaResponse> | undefined): boolean {
    return proto3.util.equals(SegmentsMetaResponse, a, b);
  }
}

/**
 * @generated from message recommenderpb.Pricing
 */
export class Pricing extends Message<Pricing> {
  /**
   * @generated from field: int32 min = 1;
   */
  min = 0;

  /**
   * @generated from field: int32 max = 2;
   */
  max = 0;

  constructor(data?: PartialMessage<Pricing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.Pricing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pricing {
    return new Pricing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pricing {
    return new Pricing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pricing {
    return new Pricing().fromJsonString(jsonString, options);
  }

  static equals(a: Pricing | PlainMessage<Pricing> | undefined, b: Pricing | PlainMessage<Pricing> | undefined): boolean {
    return proto3.util.equals(Pricing, a, b);
  }
}

/**
 * @generated from message recommenderpb.SuggestSegmentsResp
 */
export class SuggestSegmentsResp extends Message<SuggestSegmentsResp> {
  /**
   * @generated from field: repeated recommenderpb.Segment segments = 1;
   */
  segments: Segment[] = [];

  constructor(data?: PartialMessage<SuggestSegmentsResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SuggestSegmentsResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segments", kind: "message", T: Segment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestSegmentsResp {
    return new SuggestSegmentsResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestSegmentsResp {
    return new SuggestSegmentsResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestSegmentsResp {
    return new SuggestSegmentsResp().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestSegmentsResp | PlainMessage<SuggestSegmentsResp> | undefined, b: SuggestSegmentsResp | PlainMessage<SuggestSegmentsResp> | undefined): boolean {
    return proto3.util.equals(SuggestSegmentsResp, a, b);
  }
}

/**
 * @generated from message recommenderpb.SuggestByTextRequest
 */
export class SuggestByTextRequest extends Message<SuggestByTextRequest> {
  /**
   * @generated from field: string campaign_id = 1;
   */
  campaignId = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: google.protobuf.Timestamp current_time = 3;
   */
  currentTime?: Timestamp;

  /**
   * @generated from field: int32 limit = 4;
   */
  limit = 0;

  /**
   * @generated from field: recommenderpb.BooleanExpression expr = 5;
   */
  expr?: BooleanExpression;

  /**
   * @generated from field: repeated string existing_predicted_ids = 6;
   */
  existingPredictedIds: string[] = [];

  /**
   * @generated from field: repeated string current_run_existing_selected_ids = 7;
   */
  currentRunExistingSelectedIds: string[] = [];

  constructor(data?: PartialMessage<SuggestByTextRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SuggestByTextRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "current_time", kind: "message", T: Timestamp },
    { no: 4, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "expr", kind: "message", T: BooleanExpression },
    { no: 6, name: "existing_predicted_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "current_run_existing_selected_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestByTextRequest {
    return new SuggestByTextRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestByTextRequest {
    return new SuggestByTextRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestByTextRequest {
    return new SuggestByTextRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestByTextRequest | PlainMessage<SuggestByTextRequest> | undefined, b: SuggestByTextRequest | PlainMessage<SuggestByTextRequest> | undefined): boolean {
    return proto3.util.equals(SuggestByTextRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.SummaryRequest
 */
export class SummaryRequest extends Message<SummaryRequest> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  constructor(data?: PartialMessage<SummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryRequest {
    return new SummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryRequest {
    return new SummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryRequest {
    return new SummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SummaryRequest | PlainMessage<SummaryRequest> | undefined, b: SummaryRequest | PlainMessage<SummaryRequest> | undefined): boolean {
    return proto3.util.equals(SummaryRequest, a, b);
  }
}

/**
 * @generated from message recommenderpb.SummaryResponse
 */
export class SummaryResponse extends Message<SummaryResponse> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  constructor(data?: PartialMessage<SummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "recommenderpb.SummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryResponse {
    return new SummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryResponse {
    return new SummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryResponse {
    return new SummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SummaryResponse | PlainMessage<SummaryResponse> | undefined, b: SummaryResponse | PlainMessage<SummaryResponse> | undefined): boolean {
    return proto3.util.equals(SummaryResponse, a, b);
  }
}

