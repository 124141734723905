import * as Plot from '@observablehq/plot';
import { useEffect, useRef, useState } from 'react';
import { globalStyles } from './global_styles';

import styled from 'styled-components';
import { Graph_Meta } from '../../../protos/graph/graph_pb';

interface ContentProps {
  meta: Graph_Meta;
  marks: Plot.Markish[];
}

const FillDimensions = styled.div`
  height: 100%;
  width: 100%;
  min-height: 300px;
`;

const BaseGraph: React.FC<ContentProps> = ({ meta, marks }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(600);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current?.clientWidth);
      setHeight(containerRef.current?.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (meta === undefined) return;
    const plot = Plot.plot({
      width,
      height,
      ...globalStyles,
      color: { ...globalStyles.color, legend: meta?.showLegend },
      fy: { tickFormat: null, label: meta.yAxisLabel },
      fx: {
        tickFormat: meta.xTickFormat.length ? meta.xTickFormat : undefined,
        label: meta.xAxisLabel
      },
      x: {
        label: meta?.xAxisLabel,
        grid: meta?.showXAxisLines,
        tickFormat: meta.usesTimeScale
          ? undefined
          : meta.xTickFormat.length
            ? meta.xTickFormat
            : 's',
        tickSize: 0,
        ticks: 3,
        axis: meta.hideXAxis ? null : undefined
      },
      y: {
        label: meta?.yAxisLabel,
        grid: meta?.showYAxisLines,
        tickFormat: 's',
        tickSize: 0,
        ticks: 3
      },
      marks
    });
    containerRef.current?.append(plot);
    return () => plot.remove();
  }, [height, width, meta, marks]);

  return (
    <FillDimensions>
      <FillDimensions ref={containerRef} />
    </FillDimensions>
  );
};

export default BaseGraph;
