import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface GridItemProps {
  $columns?: number | string;
  $rows?: number | string;
}

const Styled = styled.div<GridItemProps>`
  height: var(--height, 100%);
  grid-column: ${(props) =>
    props.$columns === 'inherit' ? props.$columns : `span ${props.$columns}`};
  grid-row: ${(props) => (props.$rows === 'inherit' ? props.$rows : `span ${props.$rows}`)};
`;

interface GridItemComponentProps extends GridItemProps {
  children?: ReactNode;
}

const GridItem: React.FC<GridItemComponentProps> = ({
  $columns = 'inherit',
  $rows = 'inherit',
  children
}) => {
  return (
    <Styled $columns={$columns} $rows={$rows}>
      {children}
    </Styled>
  );
};

export default GridItem;
