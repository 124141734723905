import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import styled from 'styled-components';
import withClient from '../../../proto/with_client';
import { Meta } from '../../../protos/recommender/meta_connect';
import { OmniSearchResult } from '../../../protos/recommender/meta_pb';
import { campaignIdAtom } from '../../state/campaign_meta';

const Tag = styled.small`
  padding: 0 1rem 0 0;
  display: inline-block;

  span {
    padding: 0 0.2rem 0 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8em;
    color: var(--color-recorded);
  }
`;

const MetaTag: React.FC<{
  text: string;
  label: string;
  value: string;
}> = ({ text, value, label }) => {
  if (!value) return null;

  return (
    <Tag>
      <span>{label}</span>
      <Highlighter search={text}>{value}</Highlighter>
    </Tag>
  );
};

const MetaOrg: React.FC<{
  text: string;
  option: string | Record<string, any>;
  label: string;
  orgKey: string;
}> = ({ text, option, label, orgKey: optionKey }) => {
  const value = (option as Record<string, any>)[optionKey];
  return <MetaTag text={text} value={value} label={label} />;
};

const orgTypes: Record<string, string> = {
  hive: 'Hive',
  adv: 'Advertiser',
  agency: 'Agency',
  stationGroup: 'Station Group',
  station: 'Station',
  ssp: 'SSP',
  pubGroup: 'Publisher Group',
  manu: 'Manufacturer',
  dmp: 'DMP',
  dsp: 'DSP',
  unknown: 'Unknown',
  id: 'ID'
};

const OmniSearch = () => {
  const [loading, setLoading] = useState(false);
  const client = withClient(Meta);
  const [, setCampaignId] = useAtom(campaignIdAtom);
  const [items, setItems] = useState<OmniSearchResult[]>([]);
  // fIV1mvXUwsTt1PX43N7eTSA46n9R (LR)
  // uuTA7L4UM8qYlcBtdXYsggm7lWeb
  const [query, setQuery] = useState<string>('fIV1mvXUwsTt1PX43N7eTSA46n9R');

  const handleSearch = async (query: string) => {
    setQuery(query);
    setLoading(true);
    try {
      const resp = await client.omniSearch({ query: query.toLowerCase() });
      setItems(resp.results);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  // Show meta for the default query. Remove if there is no default query.
  useEffect(() => {
    handleSearch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!/\s/g.test(query)) setCampaignId(query);
    }
  };

  const handleChange = (selected: Option[]) => {
    if (!selected.length) return;
    setCampaignId((selected[0] as Record<string, any>)['id']);
  };

  return (
    <form>
      <h5>Search by Line Item Name, ID, or Client</h5>
      <AsyncTypeahead
        onKeyDown={handleKeyDown}
        defaultInputValue="fIV1mvXUwsTt1PX43N7eTSA46n9R"
        id="OmniSearch"
        labelKey="id"
        filterBy={() => true}
        onSearch={handleSearch}
        onChange={handleChange}
        renderMenuItemChildren={(option, props) => (
          <>
            <Highlighter search={props.text}>{(option as Record<string, any>)['name']}</Highlighter>
            <div>
              <MetaTag
                text={props.text}
                value={(option as Record<string, any>)['start']
                  ?.toDate()
                  .toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit' })}
                label="Start"
              />
              <MetaTag
                text={props.text}
                value={(option as Record<string, any>)['end']
                  ?.toDate()
                  .toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit' })}
                label="End"
              />
              {Object.keys(orgTypes).map((optionKey) => (
                <MetaOrg
                  key={optionKey}
                  text={props.text}
                  option={option}
                  label={orgTypes[optionKey]}
                  orgKey={optionKey}
                />
              ))}
            </div>
          </>
        )}
        isLoading={loading}
        options={items}
        autoFocus={true}
        useCache={false}
      />
    </form>
  );
};

export default OmniSearch;
